import React from 'react'

import { LogicEngine, FindTaskResults, TaskCollection, NoteCollection } from '@digitalworkflow/dwtranslateclient'
import { Modal, Col, Row, Button } from 'reactstrap'
import { LocalSettings } from '../../../utils/LocalSettings'
export interface TakeAssignTaskProps {
  item: FindTaskResults
  logic: LogicEngine | undefined
  currentUsername: string
  onClose: () => void
}

const TakeAssignTask = ({ item, logic, onClose, currentUsername }: TakeAssignTaskProps) => {
  const theme = LocalSettings.getTheme() ?? 'bgis'
  /** Click the close button on the page */
  const handleClick = async () => {
    onClose()
  }

  /** Click the update button on the page */
  const handleUpdate = async () => {
    if (!logic) return false
    const task = await TaskCollection.findTaskById(item.task_id)
    if (!task) return false
    const reason = `Task Taken by ${item.auser}`

    if (currentUsername) {
      console.log('assignUser update', currentUsername)
      await task.assignUser(currentUsername, reason, true)
      task.importIntoLogicEngine(logic)

      // Add auto log note about the change via management function
      const note = NoteCollection.createNote()
      note.setNoteText('The task has been assigned to ' + currentUsername)
      note.setReferenceObject('object_type', 'task')
      note.setReferenceObject('related_object', item.task_id)
      note.setProject(item.project_id)

      await note.save()
    }

    onClose()
    return true
  }

  return (
    <div>
      <Modal centered isOpen={item !== undefined} size='lg' className='task-reassign-modal'>
        <div className='modal-header'>
          <h6 className='modal-title'>
            Re-assign this task from <b>{`${item.auser ? item.auser : 'Nobody'} `}</b>
            to
            <b>{` Yourself?`}</b>
            <br />
          </h6>
          <button type='button' className='close' data-dismiss='modal' aria-label='Close' onClick={handleClick}>
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>

        <div className='modal-body'>
          <Row>
            <Col className='d-flex gap-2'>
              <Button
                type='submit'
                color={theme === 'io' ? 'edit' : 'primary'}
                className='btn w-xs waves-effect  floatButton btn-info'
                onClick={handleUpdate}
              >
                Yes
              </Button>
              <Button type='button' color='danger' className='btn w-xs waves-effect floatButton' onClick={onClose}>
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  )
}

export default TakeAssignTask
