import { Middleware } from '@reduxjs/toolkit'

const localStorageMiddleware: Middleware<{}, any> = (store) => (next) => (action: any) => {
  const result = next(action)
  const state = store.getState().settings

  // List of actions that should trigger local storage update
  const persistActions = [
    'settings/updateShowRejected',
    'settings/updateShowMuted',
    'settings/updateShowClosed',
    'settings/updateShowOther',
    'settings/toggleShowRejected',
    'settings/toggleShowMuted',
    'settings/toggleShowClosed',
    'settings/toggleShowOther'
  ]

  const savedValues = JSON.stringify({
    showRejected: state.showRejected,
    showMuted: state.showMuted,
    showClosed: state.showClosed,
    showOther: state.showOther
  })

  if ('type' in action && persistActions.includes(action.type)) {
    localStorage.setItem('settingsSlice', savedValues)
  }

  return result
}

export default localStorageMiddleware
