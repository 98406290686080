import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActionBarHelperRecord } from './ActionBarHelper'

import { PageEngine, TaskCollection } from '@digitalworkflow/dwtranslateclient'
import { StyleHelper } from '../PageEngine/PageButton/StyleHelper'
import { MuteTaskModal } from '../MuteTaskModal'
import { toast } from 'react-toastify'

type ActionBarMuteButtonProps = {
  button: ActionBarHelperRecord
  page: PageEngine | undefined
  handleCancelRequest: () => void
}

export const ActionBarMuteButton = (props: ActionBarMuteButtonProps) => {
  const navigate = useNavigate()
  const [showMuteTaskModal, setShowMuteTaskModal] = useState<boolean>(false)

  /** Special button Mute to mute tasks */
  const isMuteButton = () => props.button.opt.option_1.endsWith('MUTE')

  /** Called when the button has been clicked */
  const handleButtonClick = (button: ActionBarHelperRecord) => {
    if (isMuteButton()) {
      setShowMuteTaskModal(true)
    } else {
      // Internal Navigation to the correct page
      navigate(button.opt.option_1)
    }
  }

  /** Draw a single action button using the style helper to build
  the colors, margins, and padding. */

  if (!props.button.is_visible) return <div>Invisible Button</div>

  let baseStyle = 'btn w-xs waves-effect me-3 floatButton btn-primary'
  baseStyle += ' ' + new StyleHelper(props.button.opt.style).getClassNames()
  baseStyle += ' ' + new StyleHelper(props.button.opt.options).getClassNames()

  if (isMuteButton()) baseStyle += 'btn-danger'

  const handleMuteTask = async (muteDuration: number, comment?: string) => {
    const taskId = props.button.opt.logicRef?.getValue('task_id')
    if (taskId) {
      const task = await TaskCollection.findTaskById(taskId)
      const muteResponse = await task?.SetMute(true, muteDuration, comment)
      if (muteResponse?.success) {
        toast(muteResponse?.message, {
          style: {
            background: 'green',
            color: 'white',
            boxShadow: 'none',
            border: 'none'
          },
          hideProgressBar: true
        })
        navigate('/page/tasks')
      } else toast.error(muteResponse?.message)
    }
  }
  const handleCloseModal = () => setShowMuteTaskModal(false)

  return (
    <>
      <button type='button' className={baseStyle} onClick={() => handleButtonClick(props.button)}>
        {isMuteButton() && <FontAwesomeIcon icon={['fal', 'volume-xmark']} className='me-2' />}
        {props.button.opt.text}
      </button>
      <MuteTaskModal
        showMuteTaskModal={showMuteTaskModal}
        onCloseModal={handleCloseModal}
        handleMuteTask={handleMuteTask}
      />
    </>
  )
}
