import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'reactstrap'
import { StepForm, FieldError, SyncableQueryType, DataTools, StringOptions } from '@digitalworkflow/dwtranslateclient'
import { FormFieldGroup, RenderHelper } from '../../RenderHelper'
import FormFieldButton from './FormButton'

import {
  BuildingSearchInput,
  TextInput,
  BooleanInput,
  DateInput,
  LookupInput,
  Dropdown,
  AutoSuggestInput,
  MemoInput,
  MultiSelect,
  AttachmentsInput,
  NumberInput,
  SwitchInput,
  CurrencyInput,
  LookupImage,
  FormEditModal,
  QueryCreateModal,
  QueryReplyModal,
  QueryCloseModal,
  AutoCompleteInput
} from '../FormElements'

import './index.scss'
import './query.scss'

import { QueryStatus } from '@digitalworkflow/dwtranslateclient/lib/Models/QueryLog/QueryLogSchema'
import { LogicEngineEventTypes } from '@digitalworkflow/dwtranslateclient/lib/LogicEngine/LogicEngineEventTypes'
import SBFloorPlanInput from '../FormElements/SBFloorPlanInput'

const HtmlToReactParser = require('html-to-react').Parser
const htmlToReactParser = new HtmlToReactParser()

type QueryAction = {
  can_click: boolean
  action?: string
}

interface Props {
  /** Step data */
  step: StepForm
  /** Array of components or fields */
  items: (JSX.Element | StepForm.FieldInfo | FormFieldGroup)[]
  /** Optional, form submittion handler. */
  handleSubmitForm?: () => void
  /** Used for redirecting. */
  renderer: RenderHelper
}

export interface LooseObject {
  [key: string]: any
}

/**
 * PageForm component
 *
 * @component PageForm
 * @category PageElements
 */
const PageForm = ({ step, renderer, items }: Props) => {
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState<LooseObject>({})
  const [warnings, setWarnings] = useState<LooseObject>({})
  const [disabledFields, setDisabledFields] = useState<string[]>([])

  /** For Form Edit Modal */
  const [editItems, setEditItems] = useState<StepForm.FieldInfo[]>([])
  const [editValues, setEditValues] = useState<LooseObject>({})
  const [isShowEditModal, setIsShowEditModal] = useState(false)
  const [activeTab, setActiveTab] = useState(1)

  /** For Query Mode */
  const [queryItem, setQueryItem] = useState<StepForm.FieldInfo>()
  const [isShowQueryModal, setIsShowQueryModal] = useState(false)
  const [isShowQueryReplyModal, setIsShowQueryReplyModal] = useState(false)
  const [queryRecord, setQueryRecord] = useState<SyncableQueryType>()
  const [isShowQueryCloseModal, setIsShowQueryCloseModal] = useState(false)
  const [updateTrigger, setUpdateTrigger] = useState(0)

  /** Placeholder change values should show when you make changes to a field that had a placeholder */
  const [placeholderLabels, setPlaceholderLabels] = useState<{ [fieldName: string]: string | undefined }>({})

  const [, updateState] = React.useState<number>()
  const forceUpdate = React.useCallback(() => updateState((n) => (n || 0) + 1), [])

  // Help messages that appear above the query list
  const [feedbackMessage, setFeedbackMessage] = useState<{
    [fieldName: string]: string
  }>({})

  const navigate = useNavigate()
  // const location = useLocation()
  // console.log('PageForm Info', step, items)

  // step.is_edit_mode = true
  // step.is_query_mode = true
  // step.is_readonly = false

  const showChangedFields = () => {
    const other_options = new StringOptions(step.option_2)
    if (other_options.checkOption('highlight_placeholder_changes')) return true
    return false
  }

  useEffect(() => {
    if (step.logicRef) {
      //
      step.logicRef.evFieldChanged.subscribe((change: LogicEngineEventTypes.FieldEnabledEvent) => {
        if (change.enabled === false) {
          setDisabledFields((dFields) => [...dFields, change.field])
        } else {
          setDisabledFields((dFields) => {
            return dFields.filter((f) => f !== change.field)
          })
        }
      })

      step.logicRef.evFormRedraw.subscribe(() => {
        // setDisabledFields((dFields) => dFields)
        forceUpdate()
      })

      // Re-run the conditions now that the event is setup
      step.logicRef.runConditionsWithDelay()
      step.updateValidations()
    } else {
      console.log('FieldStatusChangeEvent Failed to setup due to missing logicRef')
    }
  }, [step.logicRef])

  // console.log('PageForm disabledFields:', disabledFields)

  /** Returns true if the field name passed in is currently disabled */
  const isFieldDisabled = (field: string): boolean => {
    // if (disabledFields.includes(field)) console.log('Return disabledField ' + field)
    if (disabledFields.includes(field)) return true
    return false
  }

  const handleNoSaveButton = async (targetRoute: string): Promise<void> => {
    navigate(targetRoute)
  }

  /** Scroll to the field when validation fails */
  const scrollToField = (field: string) => {
    const el: Element | null = document.querySelector(`div[data-field="${field}"]`)
    if (!el) return

    el.scrollIntoView({ behavior: 'smooth' })
  }

  const handleSave = async (targetRoute: string): Promise<void> => {
    setIsSaving(true)
    const result: FieldError[] = await renderer.submitForm(true)

    console.log('Save Validation Result: ', result)
    if (result.length === 0) {
      navigate(targetRoute)
    } else {
      const errorsObj: LooseObject = {}
      const warningsObj: LooseObject = {}
      const warningArray: string[] = []
      const errorArray: string[] = []

      result.forEach((item: FieldError) => {
        if (item.field_status === 'warning') {
          if (warningsObj[item.field_name]) {
            warningsObj[item.field_name] += '<br/>' + item.error_message
          } else {
            warningsObj[item.field_name] = item.error_message
          }
          warningArray.push(item.error_message)
        } else {
          if (errorsObj[item.field_name]) {
            errorsObj[item.field_name] += '<br/>' + item.error_message
          } else {
            errorsObj[item.field_name] = item.error_message
          }
          errorArray.push(item.error_message)
        }
      })

      console.log('Errors and Warnings:', errorsObj, warningsObj)
      setErrors(errorsObj)
      setWarnings(warningsObj)

      scrollToField(result[0].field_name)
    }
    setIsSaving(false)
  }

  /** Helper function that returns the warning value of a given field.
  Warnings can come from Form validation or from the global validations which
  are stored in the Field itself by StepForm */
  const getFieldWarning = (field: StepForm.FieldInfo): string => {
    if (warnings[field.field]) return warnings[field.field]
    if (field.warningMessage) return field.warningMessage
    return ''
  }

  /** Render the form field */
  const renderInputField = (field: StepForm.FieldInfo, is_on_modal: boolean, is_disabled?: boolean): JSX.Element => {
    const type = step.tableDef?.getFieldType(field.field)

    const formProps = {
      step,
      field,
      is_on_modal,
      is_disabled,
      error: errors[field.field] ?? '',
      warning: getFieldWarning(field),
      disabledFields: is_on_modal ? [] : disabledFields,
      editValues,
      handleChangeEditValues,
      handleSetEditValues
    }

    // console.log('renderInputField ', field.field, 'dis=', is_disabled, type)

    switch (type) {
      case 'Text':
        return <TextInput {...formProps} />
      case 'Memo':
        return <MemoInput {...formProps} />
      case 'Boolean':
        return <BooleanInput {...formProps} />
      case 'Date':
        return <DateInput {...formProps} />
      case 'Lookup':
        return <LookupInput {...formProps} /> // Multiple Radio
      case 'Dropdown':
        return <Dropdown {...formProps} />
      case 'ReferenceByID':
        return <BuildingSearchInput {...formProps} />
      case 'Floorplan':
        return <SBFloorPlanInput {...formProps} />
      case 'Autocomplete':
        return <AutoCompleteInput {...formProps} />
      case 'AutoComplete':
        return <AutoSuggestInput {...formProps} />
      case 'MultiSelect':
      case 'Multiselect':
        return <MultiSelect {...formProps} />
      case 'Attachments':
        return <AttachmentsInput {...formProps} renderer={renderer} />
      case 'Number':
        return <NumberInput {...formProps} />
      case 'Currency':
        return <CurrencyInput {...formProps} />
      case 'Switch':
        return <SwitchInput {...formProps} />
      case 'LookupImage':
        return <LookupImage {...formProps} /> // Multiple Image Radio
      default:
        return <TextInput {...formProps} />
    }
  }

  /** Return element width for the form field */
  const getRenderWidth = (field: StepForm.FieldInfo, is_on_modal: boolean) => {
    const type = step.tableDef?.getFieldType(field.field)

    switch (type) {
      case 'Switch':
      case 'Number':
      case 'Decimal':
      case 'Date':
      case 'DateTime':
      case 'Time':
      case 'Year':
      case 'ShortInput':
      case 'Currency':
        return is_on_modal ? 'col-md-6 col-lg-4' : 'col-md-6 col-lg-4'
      case 'Boolean':
        return is_on_modal ? 'col-md-6 col-lg-12' : 'col-md-6 col-lg-12'

      case 'Lookup':
      case 'Autocomplete':
      case 'AutoComplete':
      case 'Text':
      case 'Dropdown':
        return is_on_modal ? 'col-md-8' : 'col-md-12'

      default:
        return 'col-md-12'
    }
  }

  const renderHTMLField = (field: StepForm.FieldInfo): JSX.Element => {
    let classNames = 'col-md-12 mt-3 mb-2 htmlField'
    if (field.type.checkOption('Float')) classNames = 'me-3 mt-3 mb-2 float-md-start htmlField'

    return <div className={classNames}>{htmlToReactParser.parse(field.text)}</div>
  }

  // Helper function to return the JSX Element for a query if
  // the page is loaded in Query mode
  const getQueryStatusButtonQueryMode = (query: SyncableQueryType): JSX.Element => {
    switch (query.query_status) {
      case QueryStatus.Open:
        // In Query mode, the Open status means that we have already sent
        // a query and the only thing we can do is close it.
        return (
          <span className='query-status query-status-open'>
            <span className='badge bg-success rounded-pill'>Open</span> <br />
            <span className='query-action'>Click to resolve</span>
          </span>
        )
        break

      case QueryStatus.Resolved:
        // In Query mode, the Open status means that we have already sent
        // a query and the only thing we can do is close it.
        return (
          <span className='query-status query-status-open'>
            <span className='badge bg-secondary rounded-pill'>Resolved</span>{' '}
          </span>
        )
        break

      case QueryStatus.Answered:
        // TODO: Show an 'Close' button in Query mode
        // TODO: In edit mode we show answered and no reply button
        return (
          <span className='query-status query-status-ans'>
            <span className='badge bg-success rounded-pill'>The Answer</span>
            <br />
          </span>
        )
        break

      default:
        return <span className='badge bg-info rounded-pill'>[{query.query_status}]</span>
    }
  }

  const fieldHasUnrepliedQueries = (field: StepForm.FieldInfo): number => {
    const aCount = (step.record?.data.queries ?? []).filter(
      (q) => q.fid === field.field && q.query_status === QueryStatus.Open
    ).length

    const bCount = (step.record?.data.queries ?? []).filter(
      (q) => q.fid === field.field && q.query_status === QueryStatus.Answered
    ).length

    return aCount - bCount
  }

  /** Returns true if there are open queries on a field */
  const fieldHasOpenQueries = (field: StepForm.FieldInfo): boolean => {
    for (const query of step.record?.data.queries ?? []) {
      if (query.fid === field.field && query.query_status === QueryStatus.Open) return true
    }
    return false
  }

  const getQueryActionEditMode = (query: SyncableQueryType): QueryAction => {
    switch (query.query_status) {
      case QueryStatus.Open:
        return { can_click: true, action: 'ReplyQuery' }
        break
      default:
        return { can_click: false }
    }
  }

  const getQueryActionQueryMode = (query: SyncableQueryType): QueryAction => {
    switch (query.query_status) {
      case QueryStatus.Open:
        return { can_click: true, action: 'CloseQuery' }
        break
      default:
        return { can_click: false }
    }
  }

  // Helper function to return the JSX Element for a query if
  // the page is loaded in Edit mode
  const getQueryStatusButtonEditMode = (query: SyncableQueryType): JSX.Element => {
    switch (query.query_status) {
      case QueryStatus.Open:
        // TODO:
        // If editing this then return a button here to reply
        return (
          <span className='query-status query-status-open'>
            <span className='badge bg-danger rounded-pill'>Open</span>
            <br />
            <span className='query-action'>Click to Reply</span>
          </span>
        )
        break

      case QueryStatus.Resolved:
        return (
          <span className='query-status query-status-open'>
            <span className='badge bg-primary rounded-pill'>Resolved</span>
            <br />
            <span className='query-action'>No action needed</span>
          </span>
        )
        break

      case QueryStatus.Answered:
        // TODO: Show an 'Close' button in Query mode
        // TODO: In edit mode we show answered and no reply button
        return (
          <span className='query-status query-status-ans'>
            <span className='badge bg-success rounded-pill'>Answered</span>
            <br />
            <span className='query-action'>No action needed</span>
          </span>
        )
        break

      default:
        return <div className='query-status'>({query.query_status})</div>
        break
    }
  }

  /**
   * Simple helper function that sets the text above the query list and under the
   query with feedback from actions.
   * @param field
   * @param feedback
   * @param color
   */
  const helperShowFieldFeedback = (field: StepForm.FieldInfo, feedback: string, color: string) => {
    setFeedbackMessage({ [field.field]: `${color} ${feedback}` })
    setTimeout(() => {
      setFeedbackMessage({ [field.field]: '' })
    }, 2000)
  }

  /**
   * Called when you click on the list item that show a query
   * @param field The Step Form Field
   * @param query The Query Record
   * @returns
   */
  const handleQueryAction = (action: QueryAction, field: StepForm.FieldInfo, query: SyncableQueryType): undefined => {
    if (!action.can_click) return undefined

    helperShowFieldFeedback(field, 'Taking Action:' + action.action + ' on query ' + query.qid, 'info')

    switch (action.action) {
      case 'CloseQuery':
        handleShowQueryClose(query)
        break

      case 'ReplyQuery':
        showQueryReplyModal(query)
        break

      default:
        break
    }

    return undefined
  }

  const renderFieldQueries = (field: StepForm.FieldInfo) => {
    const queries = step.getQueriesForField(field.field)

    const queryListHtml = queries.map((query: SyncableQueryType) => {
      let action: QueryAction = { can_click: false }
      if (step.is_query_mode) {
        action = getQueryActionQueryMode(query)
      } else if (step.is_edit_mode) {
        action = getQueryActionEditMode(query)
      }
      return (
        <li
          key={'qry' + query.qid}
          onClick={() => handleQueryAction(action, field, query)}
          className={
            'list-group-item d-flex justify-content-between align-item-start ' + (action.can_click ? '' : 'no-click')
          }
        >
          <div className='ms-2 me-auto query-item-subheading'>
            <div className='fw-bold'>{query.message}</div>
            <div className='query-item-from'>
              {query.user_id} on {DataTools.getMoment(query.query_dt.value)?.format('MMMM Do YYYY, h:mm:ss a')}
            </div>
          </div>
          {step.is_query_mode && getQueryStatusButtonQueryMode(query)}
          {step.is_edit_mode && getQueryStatusButtonEditMode(query)}
        </li>
      )
    })

    return queryListHtml
  }

  /** Return true if the field should show a highlight.
  This happens if the field has the highlight flag enabled by StepForm
  or if the field has changed and a previous value was put into the placeHolder state
  and we have the form option_2 for showChangedFields */
  const isFieldHighlighted = (field: StepForm.FieldInfo): boolean => {
    try {
      if (field.highlight) return true
      if (showChangedFields() && placeholderLabels[field.field] !== undefined) return true
    } catch (e) {}
    return false
  }

  /** Render Form Field */
  const renderFormField = (field: StepForm.FieldInfo, is_on_modal: boolean, is_disabled?: boolean): JSX.Element => {
    // Placeholder for query feedback output
    const queryFeedbackElement = <div className='query-feedback no-click'>{feedbackMessage[field.field]}</div>

    const queryFinishedElement =
      !step.is_query_mode && fieldHasOpenQueries(field) && fieldHasUnrepliedQueries(field) === 0 && !is_on_modal ? (
        <div className='query-feedback-done no-click'>
          You have answered all the questions. This will show as an open question until it is marked as resolved by the
          person that asked. No further action is needed, however, you can click to reply with additional information if
          desired.
        </div>
      ) : (
        <></>
      )

    // const theme = localStorage.getItem('dwtheme') ?? 'bgis'
    // const labelClassname =
    // is_disabled || disabledFields[field.field] ? 'col-md-12 col-form-label disabled' : 'col-md-12 col-form-label'

    const labelClassname =
      is_disabled || disabledFields[field.field as keyof typeof disabledFields]
        ? 'col-form-label disabled'
        : 'col-form-label'

    // if (is_disabled || disabledFields[field.field]) console.log('Render field DISABLED:', field.field)
    // else console.log('Render Field non disabled:', field.field)

    const type = field.field ? step.tableDef?.getFieldType(field.field) : 'Other'

    field.text = field.text.replaceAll('<BoldBlue>', '<b style="color: blue">')
    field.text = field.text.replaceAll('</BoldBlue>', '</b>')

    field.text = field.text.replaceAll('<BoldBlueItalic>', '<i><b style="color: blue">')
    field.text = field.text.replaceAll('</BoldBlueItalic>', '</b></i>')

    field.text = field.text.replaceAll('<BoldItalic>', '<i><b>')
    field.text = field.text.replaceAll('</BoldItalic>', '</b></i>')

    /** Additional CSS around the entire field
    1- Add a highlight class if the field has the highlight flag enabled
    */
    const wrapperClassNames = '' + (isFieldHighlighted(field) ? 'highlight highlight_' + field.highlight : '')

    if (field.type.checkOption('Button')) {
      return (
        <FormFieldButton
          isDisabled={is_disabled || disabledFields.includes(field.field)}
          key={field.field}
          field={field}
          isSaving={isSaving}
          handleSave={handleSave}
          handleNoSaveButton={handleNoSaveButton}
        />
      )
    } else if (field.type.checkOption('HTML')) {
      return renderHTMLField(field)
    } else {
      return (
        <div className={wrapperClassNames}>
          {type !== 'Switch' && type !== 'ReferenceByID' && (
            <div className='label_wrapper'>
              <label
                className={`${labelClassname} label-classname
              ${field.highlight ? 'label-highlight' : ''}
              ${fieldHasOpenQueries(field) ? 'label-has-queries' : ''}
              ${isFieldDisabled(field.field) && !is_on_modal ? 'label-disabled' : ''}`}
              >
                {htmlToReactParser.parse(field.text)}
              </label>
              <span className='change_placeholder' key={'place' + field.field}>
                {placeholderLabels[field.field] || ''}
              </span>
            </div>
          )}
          <div className={`d-flex gap-3 ${getRenderWidth(field, false)}`} data-field={field.field}>
            {renderInputField(
              field,
              is_on_modal,
              is_on_modal ? is_disabled : is_disabled || isFieldDisabled(field.field)
            )}
            {/** Render Edit buttons only for fields with open queries */}
            {step.is_edit_mode && !is_on_modal && fieldHasOpenQueries(field) && renderEditButton(field)}

            {step.is_query_mode && !is_on_modal && renderQueryButton(field)}
          </div>
          {queryFeedbackElement}
          <ul className='list-group list-group-flush query-item-wrapper'>
            {!is_on_modal && renderFieldQueries(field)}
          </ul>
          {queryFinishedElement}
          {/* {(step.is_query_mode || step.is_edit_mode) &&
              !is_on_modal &&
              renderFieldHistory(field.field)} */}
        </div>
      )
    }
  }

  /** Render Edit Button next to form field */
  const renderEditButton = (field: StepForm.FieldInfo): JSX.Element => {
    return (
      <Button
        className='edit-btn'
        type='button'
        color='primary'
        disabled={isSaving}
        onClick={() => handleEditField(field)}
      >
        Edit
      </Button>
    )
  }

  /** Open Edit Modal for the field */
  const handleEditField = (field: StepForm.FieldInfo) => {
    setEditItems([field])
    setEditValues({ [field.field]: '' })
    setIsShowEditModal(true)
    setActiveTab(1)
  }

  /** Open/Close Edit Modal and reset values */
  const toggleEditModal = () => {
    setIsShowEditModal(!isShowEditModal)
    setEditItems([])
    setEditValues({})
    setActiveTab(1)
  }

  /** Called from setValue in useForm when the value of a field changes outside modal */
  const handleSetEditValues = (field: StepForm.FieldInfo, valueRaw: string, placeholder: string) => {
    // console.log('handleSetEditValues', field.field, 'raw=', valueRaw, 'place=', placeholder)

    const value = DataTools.internalValidateString(valueRaw)
    let message: string | undefined

    if (placeholder && typeof placeholder === 'string' && placeholder !== '') {
      if (placeholder !== value && value !== '') {
        if (!/yyyy/.test(placeholder)) {
          message = 'was ' + placeholder
        }
      }
    } else {
      if (showChangedFields()) {
        if (value && value !== '') message = 'was empty'
      }
    }

    setPlaceholderLabels((currentPlaceholders) => {
      return { ...currentPlaceholders, [field.field]: message }
    })
    step.updateValidations()
  }

  const handleChangeEditValues = (field: StepForm.FieldInfo, value: string) => {
    setEditValues({ ...editValues, [field.field]: value })
  }

  /** Go to the tab in the edit modal */
  const toggleTab = (tab: number) => {
    if (activeTab !== tab && editItems) {
      if (tab >= 1 && tab <= editItems.length + 1) {
        setActiveTab(tab)
      }
    }
  }

  /**
   * Save Edit modal form in edit mode
   * @param reason: A string value for reason
   * @returns {Promise<void>}
   */
  const handleEditSave = async (reason: string): Promise<void> => {
    console.log('handleEditSave reason', reason, editItems, editValues)

    if (editItems && editItems.length) {
      setIsSaving(true)
      setErrors({})
      const values: LooseObject = {}
      editItems.map((item) => {
        values[item.field] = editValues[item.field]
        return item
      })
      const result = await step.saveEditFields(values, reason)
      console.log('handleEditSave Save Validation Result: ', result)
      const errorsObj: LooseObject = {}
      if (result.length === 0) {
        /** Open Query Reply */
        // const openQueries = step.formQuery.filter((record: DataRecord) => {
        //   return (
        //     this.state.edit_items &&
        //     record.data.table_field === this.state.edit_items[0].field &&
        //     record.data.query_status === QueryLog.QueryStatus.Open
        //   )
        // })
        // await Promise.all(
        //   openQueries.map(async (query: DataRecord) => {
        //     await step.saveQueryReply(query.data.id, 'Value updated using Edit')
        //   })
        // )
        toggleEditModal()

        setIsSaving(false)
        // const pageUrl = location.pathname
        // navigate('/page/dashboard')

        // setTimeout(() => {
        //   navigate(pageUrl)
        // }, 1000)
        setUpdateTrigger((prev) => prev + 1)
        return
      }
      let newEditItems = [...editItems]
      let error_index = 0
      result.forEach((error_item: FieldError) => {
        let exist = false
        /** Check if the error field exists in the edit form steps */
        if (editItems) {
          editItems.map((item, idx) => {
            if (item.field === error_item.field_name) {
              exist = true
              error_index = idx
            }
          })
        }
        newEditItems.forEach((item: StepForm.FieldInfo) => {
          if (item.field === error_item.field_name) {
            exist = true
          }
        })
        if (!exist) {
          /** If the error field doesn't exist in the edit form steps, add them  */
          let error_field: StepForm.FieldInfo | null = null
          items.map((p_item) => {
            if ((p_item as StepForm.FieldInfo).field === error_item.field_name) {
              error_field = p_item as StepForm.FieldInfo
            }
          })
          if (error_field) {
            newEditItems = [...newEditItems, error_field]
            error_index = newEditItems.length - 1
          }
        }
        if (errorsObj[error_item.field_name]) {
          errorsObj[error_item.field_name] += '<br/>' + error_item.error_message
        } else {
          errorsObj[error_item.field_name] = error_item.error_message
        }
      })
      setEditItems(newEditItems)
      setErrors(errorsObj)
      setIsSaving(false)

      setActiveTab(error_index + 2)
    }
  }

  /** Render Query Button next to form field */
  const renderQueryButton = (field: StepForm.FieldInfo): JSX.Element => {
    return (
      <Button
        className='query-btn'
        type='button'
        color='primary'
        disabled={isSaving || disabledFields.includes(field.field)}
        onClick={() => handleQueryField(field)}
      >
        Ask
      </Button>
    )
  }

  /** Open Query Modal for the field */
  const handleQueryField = (field: StepForm.FieldInfo) => {
    setQueryItem(field)
    setIsShowQueryModal(true)
  }

  /** Open/Close Query modal and reset values */
  const toggleQueryModal = () => {
    setIsShowQueryModal(false)
    setIsSaving(false)
  }

  /** Save Query for field */
  const handleQuerySave = async (message: string) => {
    if (queryItem) {
      setIsSaving(true)

      /** TODO: Task Lock feature is needed ?  */
      // const currentLockStatus = this.state.formLockStatus

      // if (currentLockStatus) {
      //   /** Unlock task */
      //   await this.updateTaskLockStatus(false)
      // }

      console.log('saveQueryField(', queryItem.field, ',', message, ');')
      await step.saveQueryField(queryItem.field, message)

      setIsSaving(false)
      toggleQueryModal()
      // toggleQuerySuccessModal()
    }
  }

  /** Open Reply Modal for the field */
  const showQueryReplyModal = (query: SyncableQueryType) => {
    setQueryRecord(query)
    setIsShowQueryReplyModal(true)
  }

  /** Open/Close Reply modal and reset values */
  const toggleQueryReplyModal = () => {
    setIsShowQueryReplyModal(false)
    setIsSaving(false)
  }

  /** Save Query Reply in edit & query mode */
  const handleQueryReplySave = async (reply: string) => {
    console.log('Reply', queryRecord, reply)
    if (queryRecord) {
      setIsSaving(true)

      await step.saveQueryReply(queryRecord.qid, reply)

      setIsSaving(false)
      toggleQueryReplyModal()
      // setTimeout(() => {
      //   window.location.reload()
      // }, 1000)
    }
  }

  const handleShowQueryClose = (query: SyncableQueryType) => {
    setQueryRecord(query)
    setIsShowQueryCloseModal(true)
  }

  /** Open/Close Query Close modal and reset values */
  const toggleQueryCloseModal = () => {
    setIsShowQueryCloseModal(false)
  }

  /** Close the query */
  const handleQueryClose = async () => {
    if (queryRecord) {
      setIsSaving(true)

      await step.closeQuery(queryRecord.qid, QueryStatus.Resolved)

      setIsSaving(false)
      toggleQueryCloseModal()
    }
  }

  const isDisabled = step.is_readonly || step.is_edit_mode || step.is_query_mode || isSaving

  // See if there is an overwrite on modes
  // console.log('LogicRef=', step.logicRef)

  const isNoQuery = step.logicRef?.getValue('noquery')
  if (isNoQuery) {
    step.is_query_mode = false
    step.is_edit_mode = false
    step.is_readonly = false
  }

  return (
    <div>
      {items.map((item, i) => (
        <div key={`item_${i}_${updateTrigger}`}>{renderFormField(item as StepForm.FieldInfo, false, isDisabled)}</div>
      ))}
      <FormEditModal
        isShowEditModal={isShowEditModal}
        handleEditSave={handleEditSave}
        toggleEditModal={toggleEditModal}
        edit_items={editItems}
        renderFormField={renderFormField}
        activeTab={activeTab}
        toggleTab={toggleTab}
      />
      <QueryCreateModal
        isShowQueryModal={isShowQueryModal}
        handleQuerySave={handleQuerySave}
        toggleQueryModal={toggleQueryModal}
        queryItem={queryItem}
      />
      <QueryReplyModal
        isShowQueryReplyModal={isShowQueryReplyModal}
        handleQueryReplySave={handleQueryReplySave}
        toggleQueryReplyModal={toggleQueryReplyModal}
        queryRecord={queryRecord}
        is_edit_mode={step.is_edit_mode}
        is_query_mode={step.is_query_mode}
      />
      <QueryCloseModal
        isShowQueryCloseModal={isShowQueryCloseModal}
        handleQueryClose={handleQueryClose}
        toggleQueryCloseModal={toggleQueryCloseModal}
        queryRecord={queryRecord}
        is_edit_mode={step.is_edit_mode}
        is_query_mode={step.is_query_mode}
      />
    </div>
  )
}

export default PageForm
