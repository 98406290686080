import { DataTools, FindTaskResults, StringOptions } from '@digitalworkflow/dwtranslateclient'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type ColumnFilterConfig = {
  [columnName: string]: string | RegExp
}

export type TaskListSliceState = {
  /** Task List that has been filtered by options */
  taskList: FindTaskResults[]

  /** Task list received by StepTaskList */
  allTasks: FindTaskResults[]

  loaded: boolean
  activeFilters: ColumnFilterConfig
}

export type TaskListSliceType = 'todo' | 'dashboard' | 'search'

const initialState: Record<TaskListSliceType, TaskListSliceState> = {
  todo: {
    taskList: [],
    allTasks: [],
    loaded: false,
    activeFilters: {}
  },
  dashboard: {
    taskList: [],
    allTasks: [],
    loaded: false,
    activeFilters: {}
  },
  search: {
    taskList: [],
    allTasks: [],
    loaded: false,
    activeFilters: {}
  }
}

type UpdatePayload = { sname: TaskListSliceType; list: FindTaskResults[] }
type UpdateLoadedPayload = { sname: TaskListSliceType; isLoaded: boolean }

const tasklistSlice = createSlice({
  name: 'players',
  initialState,
  reducers: {
    updateActiveFilters: (state, action: PayloadAction<{ sname: TaskListSliceType; filters: string }>) => {
      const opt: ColumnFilterConfig = {}
      const filters = new StringOptions(action.payload.filters)
      for (const key of filters.Keys()) {
        opt[key] = DataTools.internalValidateString(filters.getValue(key, ''))
      }

      state[action.payload.sname] = {
        ...state[action.payload.sname],
        activeFilters: opt
      }
    },
    updateTaskListLoaded: (state, action: PayloadAction<UpdateLoadedPayload>) => {
      state[action.payload.sname] = {
        ...state[action.payload.sname],
        loaded: action.payload.isLoaded
      }
    },
    updateTaskList: (state, action: PayloadAction<UpdatePayload>) => {
      state[action.payload.sname] = {
        ...state[action.payload.sname],
        taskList: [...action.payload.list]
      }
    },
    updateAllTaskList: (state, action: PayloadAction<UpdatePayload>) => {
      state[action.payload.sname] = {
        ...state[action.payload.sname],
        allTasks: [...action.payload.list]
      }
    }
  }
})

export const { updateTaskList, updateAllTaskList, updateTaskListLoaded, updateActiveFilters } = tasklistSlice.actions
export default tasklistSlice.reducer
