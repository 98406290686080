import React from 'react'

import { SBFloorPlan } from '@digitalworkflow/sbpcommon'

import { FormFieldInputProps } from '../useForm'

const SBFloorPlanInput = ({
  step,
  field,
  is_on_modal,
  is_disabled,
  error,
  warning,
  disabledFields,
  editValues,
  handleChangeEditValues,
  handleSetEditValues
}: FormFieldInputProps) => {
  console.log({
    step,
    field,
    is_on_modal,
    is_disabled,
    error,
    warning,
    disabledFields,
    editValues,
    handleChangeEditValues,
    handleSetEditValues
  })

  const buildingId = step.logicRef?.getValue('building_id')
  const editMode = step.logicRef?.getValue('mode') === 'edit'
  const sensor_name = step.logicRef?.getValue('sensor_name')?.split('/')[0]
  const floor_id = step.logicRef?.getValue('floor_id')
  const asset_Type = step.logicRef?.getValue('sensor_type')

  return (
    <SBFloorPlan
      buildingId={buildingId}
      editMode={editMode}
      assetName={sensor_name}
      floorId={floor_id}
      assetType={asset_Type}
    />
  )
}
export default SBFloorPlanInput
