import React, { useEffect } from 'react'
import SyncModal from '../SyncModal'
import { Button, Table, Col, Row } from 'reactstrap'
import { useForceUpdateData } from './../../utils/hooks/useForceUpdateData'
import './ForceUpdate.scss'

const ForceUpdate = () => {
  const { syncing, width, message, afterdb, beforedb, showDiagnostics, setShowDiagnostics, forceUpdate } =
    useForceUpdateData()

  const onClickShowDiagnostics = () => {
    setShowDiagnostics(!showDiagnostics)
  }

  useEffect(() => {
    forceUpdate()
  }, [])

  return (
    <Row>
      <h6 className='ms-2'>{message}</h6>
      <div>
        <Button color='primary' className='mx-1 px-4' onClick={onClickShowDiagnostics}>
          {showDiagnostics ? 'Hide' : 'Show'} Diagnostics
        </Button>
      </div>
      {showDiagnostics ? (
        <Col className='col-md-8 m-3'>
          <Table size='sm' responsive className='border table-bordered border-secondary'>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Before</th>
                <th>After</th>
                <th>Difference</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(afterdb).map((k, idx) => (
                <tr key={idx}>
                  <th scope='row'>{idx + 1}</th>
                  <td>{k}</td>
                  <td>{beforedb[k]}</td>
                  <td>{afterdb[k]}</td>
                  <td>{(afterdb[k] || 0) - (beforedb[k] || 0)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      ) : null}
      <SyncModal width={width} isOpen={syncing} />
    </Row>
  )
}

export default ForceUpdate
