import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface SettingsState {
  showRejected: boolean
  showMuted: boolean
  showClosed: boolean
  showOther: boolean
  currentUsername: string
  currentRoute: string | undefined

  /** When searching with the global smart search, this is the search term */
  currentSearchString: string
  isDatabaseSynced: boolean
}

const initialState: SettingsState = {
  showRejected: false,
  showMuted: false,
  showClosed: false,
  showOther: false,
  currentRoute: undefined,
  currentUsername: '',
  currentSearchString: '',
  isDatabaseSynced: false
}

const loadInitialState = (): SettingsState => {
  const savedSettings = localStorage.getItem('settingsSlice')
  if (savedSettings) {
    const parsedSettings = JSON.parse(savedSettings)
    return {
      ...initialState,
      ...parsedSettings
    }
  }
  return initialState
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState: loadInitialState(),
  reducers: {
    updateShowRejected: (state, action: PayloadAction<boolean>) => {
      state.showRejected = action.payload
    },
    updateShowMuted: (state, action: PayloadAction<boolean>) => {
      state.showMuted = action.payload
    },
    updateShowOther: (state, action: PayloadAction<boolean>) => {
      state.showOther = action.payload
    },
    updateShowClosed: (state, action: PayloadAction<boolean>) => {
      state.showClosed = action.payload
    },
    toggleShowRejected: (state) => {
      state.showRejected = !state.showRejected
    },
    toggleShowMuted: (state) => {
      state.showMuted = !state.showMuted
    },
    toggleShowClosed: (state) => {
      state.showClosed = !state.showClosed
    },
    toggleShowOther: (state) => {
      state.showOther = !state.showOther
    },
    setCurrentUsername: (state, action: PayloadAction<string>) => {
      state.currentUsername = action.payload
    },
    setCurrentRoute: (state, action: PayloadAction<string | undefined>) => {
      state.currentRoute = action.payload
    },
    updateSearchTerm: (state, action: PayloadAction<string>) => {
      state.currentSearchString = action.payload
    },
    setDatabaseSynced: (state, action: PayloadAction<boolean>) => {
      state.isDatabaseSynced = action.payload
    }
  }
})

export const {
  toggleShowRejected,
  toggleShowMuted,
  toggleShowClosed,
  toggleShowOther,
  updateShowRejected,
  updateShowMuted,
  updateShowOther,
  updateShowClosed,
  setCurrentUsername,
  setCurrentRoute,
  updateSearchTerm,
  setDatabaseSynced
} = settingsSlice.actions
export default settingsSlice.reducer
