import React, { useState } from 'react'
import { Alert, Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'

type MuteTaskModalProps = {
  showMuteTaskModal: boolean
  onCloseModal: () => void
  handleMuteTask: (muteDurationInMinutes: number, comment?: string) => Promise<void>
}

export const MuteTaskModal = ({ showMuteTaskModal, onCloseModal, handleMuteTask }: MuteTaskModalProps) => {
  const [selectedDuration, setSelectedDuration] = useState<number | null>(null)
  const [customTime, setCustomTime] = useState<number>(5)
  const [customTimeUnit, setCustomTimeUnit] = useState<'minutes' | 'hours' | 'days'>('minutes')
  const [comment, setComment] = useState<string>('')
  const [error, setError] = useState<string>('')

  const predefinedDurations = [
    { label: '10 minutes', value: 10 },
    { label: '30 minutes', value: 30 },
    { label: '1 hour', value: 60 },
    { label: '2 hours', value: 120 },
    { label: '6 hours', value: 360 },
    { label: '1 day', value: 1440 },
    { label: '3 days', value: 4320 },
    { label: '5 days', value: 7200 },
    { label: '7 days', value: 10080 },
    { label: 'Custom Time', value: null }
  ]
  const resetForm = () => {
    setSelectedDuration(null)
    setCustomTime(5)
    setCustomTimeUnit('minutes')
    setComment('')
    setError('')
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!selectedDuration && !customTime) {
      setError('Please select a duration or enter a custom time')
      return
    }
    const durationInMinutes = calculateDurationInMinutes()
    if (durationInMinutes < 5) {
      setError('Minimum mute duration is 5 minutes')
      return
    }
    handleMuteTask(durationInMinutes, comment)
    onCloseModal()
    resetForm()
  }

  const calculateDurationInMinutes = (): number => {
    if (selectedDuration) return selectedDuration
    switch (customTimeUnit) {
      case 'minutes':
        return customTime
      case 'hours':
        return customTime * 60
      case 'days':
        return customTime * 24 * 60
    }
  }

  return (
    <Modal
      isOpen={showMuteTaskModal}
      toggle={() => {
        onCloseModal()
        resetForm()
      }}
      size='lg'
    >
      <ModalHeader
        toggle={() => {
          onCloseModal()
          resetForm()
        }}
      >
        Mute Task
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Mute Alert For:</Label>
            <div className='w-100 d-flex flex-row flex-wrap'>
              {predefinedDurations.map((duration) => (
                <Button
                  key={duration.value}
                  size='sm'
                  color='primary'
                  outline={duration.value !== selectedDuration}
                  onClick={() => {
                    setSelectedDuration(duration.value)
                    setError('')
                    if (duration.value === null) {
                      setCustomTime(5)
                    }
                  }}
                  className='me-2 mb-2'
                >
                  {duration.label}
                </Button>
              ))}
            </div>
          </FormGroup>
          {selectedDuration === null && (
            <FormGroup>
              <Label>Custom Time:</Label>
              <Row>
                <Col sm={6}>
                  <Input
                    type='number'
                    value={customTime}
                    // min={5}
                    onChange={(e) => {
                      const value = Number(e.target.value)
                      setSelectedDuration(null)
                      setCustomTime(value)
                    }}
                  />
                </Col>
                <Col sm={6}>
                  <Input
                    type='select'
                    value={customTimeUnit}
                    onChange={(e) => setCustomTimeUnit(e.target.value as 'minutes' | 'hours' | 'days')}
                  >
                    <option value='minutes'>minutes</option>
                    <option value='hours'>hours</option>
                    <option value='days'>days</option>
                  </Input>
                </Col>
              </Row>
            </FormGroup>
          )}

          <FormGroup>
            <Label for='comment'>Comment (optional):</Label>
            <Input id='comment' type='textarea' value={comment} onChange={(e) => setComment(e.target.value)} rows={3} />
          </FormGroup>

          {error && (
            <Alert fade color='danger'>
              {error}
            </Alert>
          )}
          <Button color='danger' type='submit'>
            Mute Task
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  )
}
