import React from 'react'

import {
  DataTools,
  PageRenderBase,
  PageStepType,
  StepBreak,
  StepButton,
  StepDashButton,
  StepDashStat,
  StepForm,
  StepGroup,
  StepList,
  StepMap,
  StepSection,
  StepStates,
  StepTable,
  StepTabs,
  StepText,
  StepTypes
} from '@digitalworkflow/dwtranslateclient'
import { StepWidget } from '@digitalworkflow/dwtranslateclient/lib/PageEngine/StepWidget'

import { BreadcrumbItem } from '../types/breadcrumbs'
import { ActionBarHelper } from './ActionBar/ActionBarHelper'
import {
  PageButton,
  PageForm,
  PageGrid,
  PageGroup,
  PageRedirect,
  PageSection,
  PageTable,
  PageTabs,
  PageText
} from './PageEngine'
import PageTableTimline from './PageEngine/PageTable/PageTableTimline'
import PageWidget from './PageEngine/PageWidget'
import StepMapBox from './PageEngine/StepMapBox'
import TaskList from './PageEngine/TaskList/TaskList'
import WorkFlowStateCard from './PageEngine/WorkflowStateCard'
import WorkflowShare from './PageEngine/WorkflowStateCard/WorkflowShare'

export interface FormFieldGroup {
  key: string
  type: string
  children: (StepForm.FieldInfo | JSX.Element)[]
}

/**
 * RenderHelper component.
 *
 * @remarks
 * RenderHelper that prepares the components for render
 *
 * @component RenderHelper
 * @category Component
 */
export class RenderHelper extends PageRenderBase {
  /**
   * When working with a "tree" of components we are always
   * adding to one branch.  That is a JSX.Element[] list.
   * We keep a list of branches so that when we are done with
   * the current one, we can go back to the previous
   **/
  componentTargets: (JSX.Element | StepForm.FieldInfo | FormFieldGroup)[][] = []
  currentTarget: (JSX.Element | StepForm.FieldInfo | FormFieldGroup)[] = []
  branches: string[] = []

  breadcrumbItems: BreadcrumbItem[] = []
  actionBarHelper: ActionBarHelper = new ActionBarHelper()

  currentList: JSX.Element[] | undefined
  currentListItem: any | undefined
  currentListItems: any[] | undefined
  navigate: any
  location: any
  context: any

  // Show additional debugging information in the console
  showDebug: boolean = false

  actionBarEvents: ActionBarHelper | undefined

  /** When starting a new group or collection of components,
   * use pushBranch to start a new list of components */
  pushBranch(name: string) {
    // Comment this line in production, name is just used for debugging
    // Save the current target to the array
    this.componentTargets.push(this.currentTarget)
    // Create a new target for components to be added to
    this.currentTarget = []
    // Set current Branch name
    this.branches.push(name)
  }

  /** When finished with a list or group then use this to go back
   * to the previous target
   * @return The current list of elements
   */
  popBranch(): (JSX.Element | StepForm.FieldInfo | FormFieldGroup)[] {
    // Current branch is done, return it to the caller and hope that
    // it used for something.
    const list: (JSX.Element | StepForm.FieldInfo | FormFieldGroup)[] = []
    this.currentTarget.forEach((e) => {
      list.push(e)
    })

    const top = this.componentTargets.pop()
    if (top) this.currentTarget = top
    else this.currentTarget = []

    this.branches.pop()

    return list
  }

  addComponent(component: JSX.Element) {
    if (this.currentList) {
      this.currentList.push(component)
    } else {
      this.currentTarget.push(component)
    }
  }

  debugForm(variableName: string, dataValue?: any | undefined) {
    if (this.showDebug) console.log('Debug Form: ', variableName, dataValue)
  }

  debugMessage(message: string) {
    if (this.showDebug) console.log('Debug Message: ', message)
  }

  renderRedirectPage(strNewPage: string): void {
    if (this.showDebug) console.log('Redirecting to new page', strNewPage)
    console.log('Redirecting to new page', strNewPage)
    this.addComponent(<PageRedirect key={'pageredirect' + strNewPage} strNewPage={strNewPage} />)
  }

  async renderText(opt: StepText): Promise<void> {
    if (this.showDebug) console.log('Render Text:', opt)
    const lastBranch = this.branches.length ? this.branches[this.branches.length - 1] : ''
    const option1Text = await this.logicRef?.processTextReplacement(opt.option_1)
    const option2Text = await this.logicRef?.processTextReplacement(opt.option_2)

    if (opt.options.checkOption('Breadcrumbs')) {
      const emptyBreadCrumb = opt.text[0] === '/'
      if (emptyBreadCrumb) {
        if (this.breadcrumbItems.length === 1 && this.breadcrumbItems[0].text === opt.text.substring(1)) {
          // Prevent infinite reloading on the same page
          return
        }
        this.breadcrumbItems = [
          {
            text: opt.text.substring(1),
            url: window.location.href
          }
        ]
      } else {
        const existIndex = this.breadcrumbItems.findIndex((b) => b.text === opt.text)
        if (existIndex === -1) {
          this.breadcrumbItems.push({
            text: opt.text,
            url: window.location.href
          })
        } else {
          this.breadcrumbItems = this.breadcrumbItems.slice(0, existIndex + 1)
        }
      }
    } else {
      this.addComponent(
        <PageText
          key={opt.key_id}
          step={opt}
          text={opt.text}
          option1Text={option1Text ?? ''}
          option2Text={option2Text ?? ''}
          wrapper={lastBranch}
        ></PageText>
      )
    }
  }

  renderWorkflowStates = async (opt: StepStates) => {
    const task_id_var = this.logicRef?.getVariable('task_id')
    const task_id = task_id_var ? task_id_var.value : ''
    const link = '/_task' + task_id + '/save_pdf'

    // TODO:  Move this to another file?
    // TODO:  The state.selected is not working
    const list: JSX.Element[] = []
    let counter: number = 0
    for (const state of opt.states) {
      counter++
      if (this.showDebug) console.log('state=', state)
      list.push(
        <WorkFlowStateCard
          key={opt.key_id + counter}
          state={state}
          currentState={counter}
          totalState={opt.states.length}
        />
      )
    }

    this.addComponent(
      <div key={opt.key_id + 'main'} className='all-workflow-states d-md-flex flex-row gap-2'>
        {list}
        <div className='d-md-flex flex-column workflowgap'></div>
        <WorkflowShare pdf_path={link} />
      </div>
    )
  }

  renderBreak(opt: StepBreak): void {
    if (this.showDebug) console.log('Render Break:', opt)
    this.addComponent(<br key={opt.key_id} />)
  }

  async renderButtonAction(opt: StepButton): Promise<void> {
    if (this.showDebug) console.log('Action Button:', opt)
    if (this.actionBarEvents) {
      this.actionBarEvents.addButton(opt)
    }
  }

  async renderButton(opt: StepButton): Promise<void> {
    if (this.showDebug) console.log('Render Button:', opt)

    const actionUrl = opt.option_1
    const lastBranch = this.branches.length ? this.branches[this.branches.length - 1] : ''

    const rawStyle = DataTools.internalValidateString(opt.raw.style)
    opt.raw.style = await this.logicRef?.processTextReplacement(rawStyle)

    this.addComponent(
      <PageButton
        key={opt.key_id}
        wrapper={lastBranch}
        step={opt}
        renderer={this}
        targetAction={actionUrl ?? ''}
      ></PageButton>
    )
  }

  startGroup(opt: StepGroup): void {
    // this.doc?.addSubSection(opt.text)
    if (this.showDebug) console.log('Render Start Group:', opt)
    this.pushBranch('StartGroup')
  }

  endGroup(opt: StepGroup): void {
    if (this.showDebug) console.log('Render End Group:', opt)
    const currentGroup = this.popBranch()
    /** Add each component to the list with a View wrapper that has the percent set */
    const list: JSX.Element[] = []

    currentGroup.forEach((item) => {
      if (Object.prototype.hasOwnProperty.call(item, 'key')) {
        if ((item as JSX.Element).key === null) {
          if (this.showDebug) console.log('Unexpected null key in group', item)
        }
        list.push(<React.Fragment key={'group-item' + (item as JSX.Element).key}>{item as JSX.Element}</React.Fragment>)
      } else {
        console.warn('endGroup unexpected issue with non JSX Element in group:', item)
      }
    })

    const group = <PageGroup key={opt.key_id} step={opt} components={list}></PageGroup>
    this.addComponent(group)
  }

  startSection(opt: StepSection): void {
    this.pushBranch('StartSection')
    if (this.showDebug) console.log('Render Start Section:', opt)
  }

  endSection(opt: StepSection): void {
    const currentSection = this.popBranch()
    /** Add each component to the list with a View wrapper that has the percent set */
    const list: JSX.Element[] = []
    currentSection.forEach((item) => {
      if (Object.prototype.hasOwnProperty.call(item, 'key')) {
        if ((item as JSX.Element).key === null) {
          if (this.showDebug) console.log('Unexpected null key in group 2', item)
        }
        list.push(<React.Fragment key={'group-item' + (item as JSX.Element).key}>{item as JSX.Element}</React.Fragment>)
      } else {
        console.warn('endSection unexpected issue with non JSX Element in group:', item)
      }
    })

    const section = <PageSection key={opt.key_id} step={opt} components={list}></PageSection>
    this.addComponent(section)
    if (this.showDebug) console.log('Render End Section:', opt)
  }

  renderDashStat(opt: StepDashStat): void {
    if (this.showDebug) console.log('Render Dash Stat:', opt)
  }

  renderDashButton(opt: StepDashButton): void {
    if (this.showDebug) console.log('Render Dash Button:', opt)
  }

  renderTaskList(opt: StepTypes.TaskList): void {
    console.log('Start Render Task List', opt)
    this.addComponent(<TaskList key={opt.key_id} step={opt} renderer={this} logic={this.logicRef} />)
  }

  renderTable(opt: StepTable): void {
    if (this.showDebug) console.log('Render Table:', opt)
    if (opt.option_1 === 'DataGrid') {
      this.addComponent(<PageGrid key={opt.key_id} step={opt}></PageGrid>)
    } else if (opt.option_1 === 'Timeline') {
      this.addComponent(<PageTableTimline key={opt.key_id} step={opt}></PageTableTimline>)
    } else {
      this.addComponent(<PageTable key={opt.key_id} step={opt}></PageTable>)
    }
  }

  renderUnknown(opt: PageStepType): void {
    if (this.showDebug) console.log('Render Unknown:', opt)
  }

  /** Called from the Begin Tabs function */
  beginTabs(opt: StepTypes.Tabs): void {
    if (this.showDebug) console.log('Begin the tabs:', opt.tabs)
  }

  /** Called from the End Tabs Function */
  endTabs(opt: StepTypes.Tabs): void {
    if (this.showDebug) console.log('Draw the tabs:', opt.tabs)
    this.addComponent(<PageTabs key={opt.key_id} step={opt} tabs={opt.tabs}></PageTabs>)
  }

  renderTabStart(opt: StepTypes.Tabs, num: number): void {
    if (this.showDebug) console.log('Render Tab Start:', opt, num)
  }

  renderTabEnd(opt: StepTypes.Tabs, num: number): void {
    if (this.showDebug) console.log('Render Tab End:', opt, num)
  }

  renderTabs(opt: StepTabs): void {
    if (this.showDebug) console.log('Render Tabs:', opt)
  }

  renderFormField(opt: StepForm, field: StepForm.FieldInfo): void {
    if (this.showDebug) console.log('Render Form Field:', opt, field)
    /** Add the form field to the current list of compontents */
    this.currentTarget.push(field)
    opt.updateValidations()
  }

  renderFormStart(opt: StepForm): void {
    // if (this.showDebug) console.log('Render Form Start:', opt)
    opt.fields.forEach((e: StepForm.FieldInfo) => {
      if (!e.type.checkOption('Button')) {
        if (this.showDebug) console.log('RenderHelper FormStart Button Value=', opt.getValueDatabase(e.field))
      }
    })
    this.pushBranch('Form Start')
  }

  renderFormEnd(opt: StepForm): void {
    if (this.showDebug) console.log('Render Form End:', opt)
    const itemList = this.popBranch()
    this.addComponent(<PageForm key={opt.key_id} step={opt} items={itemList} renderer={this}></PageForm>)
  }

  renderListStart(opt: StepList): void {
    if (this.showDebug) console.log('Render List Start:', opt)
  }

  renderListEnd(opt: StepList): void {
    if (this.showDebug) console.log('Render List End:', opt)
  }

  renderListItemStart(opt: StepList): void {
    if (this.showDebug) console.log('Render List Item Start:', opt)
  }

  renderListItemEnd(opt: StepList): void {
    if (this.showDebug) console.log('Render List Item End:', opt)
  }

  renderWidget(opt: StepWidget): void {
    this.addComponent(<PageWidget key={opt.key_id} step={opt} />)
  }

  renderBeginMap(opt: StepMap): void {
    if (this.showDebug) console.log('Render begin map:', opt)
    this.pushBranch('Map Start')
  }

  renderEndMap(opt: StepMap): void {
    if (this.showDebug) console.log('Render stepMap End:', opt)
    this.popBranch()
    console.log('Render stepMap End:', opt)

    this.addComponent(<StepMapBox step={opt} />)
  }
}
