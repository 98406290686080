import { LocalSettings } from '../../../utils/LocalSettings'

export const checkIsUserAuthorized = (auser: string, createdBy: string, canManage: boolean): boolean => {
  // Get user info string from local settings
  const userInfoString = LocalSettings.getPortalUser()
  // Check if the current user is an admin or developer
  if (canManage === true) {
    console.log('User is admin or developer')
    return true
  }

  // Check if user info exists and is valid
  if (userInfoString) {
    try {
      const userInfo = JSON.parse(userInfoString)
      // Ensure the user is owner/creater or assignee
      if (
        userInfo &&
        typeof userInfo.email === 'string' &&
        (userInfo.email === auser || userInfo.email === createdBy)
      ) {
        return true
      }
    } catch (error) {
      console.error('Error parsing user info:', error)
    }
  }

  // if user is not authorized then check if task assigned to nobody
  if (auser === '') return false

  // If none of the conditions match, return false
  return false
}
