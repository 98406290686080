import './pagetable.scss'

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { StepTable } from '@digitalworkflow/dwtranslateclient'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const HtmlToReactParser = require('html-to-react').Parser
const htmlToReactParser = new HtmlToReactParser()

/**
 * Props for [[`PageTable`]] component
 */
export interface PageTableProps {
  /** Step data. */
  step: StepTable
}

/**
 * PageTable component
 *
 * @component PageTable
 * @category PageElements
 */
const PageTableTimline = ({ step }: PageTableProps) => {
  const [tableState, setTableState] = useState({
    sortKey: '',
    sortKeyIndex: -1,
    sortDirection: '',
    tableData: step.getFormattedData()
  })

  const getSortedArray = () => {
    return step.getFormattedData()
  }

  useEffect(() => {
    setTableState({
      sortKey: '',
      sortKeyIndex: 0,
      sortDirection: 'asc',
      tableData: getSortedArray()
    })
  }, [])

  return (
    <div className='table-timeline'>
      <ul className='timeline-list'>
        {tableState.tableData.map((row: any[], rowIndex: number) => {
          return (
            <li key={'body-row-' + rowIndex}>
              {row.map((cellData: any, colIndex: number) => {
                const cellText = cellData.text
                let myClassName = step.columns[colIndex].text.replace(/ /g, '').toLocaleLowerCase() + ' '
                if (colIndex === 0) myClassName += 'time'

                return (
                  <div
                    className={myClassName}
                    key={'body-row-' + rowIndex + '-col-' + step.columns[colIndex].text + colIndex}
                    style={{}}
                    id={`cell-${rowIndex}-${colIndex}`}
                  >
                    {step.columns[colIndex].is_action ? (
                      cellData.text ? (
                        <Link to={cellData.link!} className='btn btn-primary btn-sm w-xs'>
                          {cellData.text}
                          {cellData.icon && <i style={{ paddingLeft: '10px' }} className={cellData.icon} />}
                        </Link>
                      ) : (
                        ''
                      )
                    ) : cellText === 'undefined' || cellText === undefined || cellText === null ? (
                      ''
                    ) : (
                      htmlToReactParser.parse(cellText)
                    )}
                  </div>
                )
              })}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default PageTableTimline
