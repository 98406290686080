import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Form, InputGroup, Modal, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'

import { StepForm } from '@digitalworkflow/dwtranslateclient'

import { sanitizeHtml } from '../../../../helpers'

/**
 * Props for [[`FormEditModal`]] component
 */
interface ModalProps {
  isShowEditModal: boolean | undefined
  handleEditSave: (reason: string) => Promise<void>
  toggleEditModal: () => void
  edit_items?: Array<StepForm.FieldInfo>
  renderFormField: (field: StepForm.FieldInfo, is_on_modal: boolean, is_disabled?: boolean) => JSX.Element
  activeTab: number
  toggleTab: (tab: number) => void
}

/**
 * FormEditModal component
 *
 * @component FormEditModal
 * @category FormElements
 */
const FormEditModal = ({
  isShowEditModal,
  handleEditSave,
  toggleEditModal,
  edit_items,
  renderFormField,
  activeTab,
  toggleTab
}: ModalProps) => {
  const [reason, setReason] = useState<string>('')
  const [error, setError] = useState('')

  const handleCloseEditModal = () => {
    setReason('')
    setError('')
    toggleEditModal()
    toggleTab(1)
  }

  return (
    <Modal size='lg' isOpen={isShowEditModal}>
      <div className='modal-header'>
        <h5 className='modal-title mt-0'>Change value</h5>
        <button type='button' onClick={handleCloseEditModal} className='close' data-dismiss='modal' aria-label='Close'>
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
      <div className='modal-body'>
        {edit_items && edit_items.length && (
          <div className='wizard clearfix'>
            <div className='steps clearfix'>
              <ul>
                <NavItem className={activeTab === 1 ? 'current' : ''}>
                  <NavLink
                    className={activeTab === 1 ? 'current' : ''}
                    onClick={() => {
                      toggleTab(1)
                    }}
                  >
                    <span className='number'>1</span> Change Reason
                  </NavLink>
                </NavItem>
                {edit_items.map((item: StepForm.FieldInfo, index: number) => {
                  return (
                    <NavItem key={'nav' + index} className={activeTab === 2 + index ? 'current' : ''}>
                      <NavLink
                        className={activeTab === 2 + index ? 'current' : ''}
                        onClick={() => {
                          if (reason.length > 0) {
                            setError('')
                            toggleTab(2 + index)
                          } else {
                            setError('This field is required and may not be left empty.')
                          }
                        }}
                      >
                        <span className='number'>{2 + index}</span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(item.text)
                          }}
                        />
                      </NavLink>
                    </NavItem>
                  )
                })}
              </ul>
            </div>
            <div className='content clearfix mt-4'>
              <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                  <Form
                    onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                      e.preventDefault()
                    }}
                  >
                    <div className='col-md-12'>
                      <label className='col-md-12 col-form-label'>Reason for change:</label>
                      <div className='col-md-12'>
                        <InputGroup>
                          <input
                            className='form-control'
                            name='reason'
                            value={reason}
                            type='text'
                            onChange={(e: any): void => {
                              setReason(e.target.value)
                            }}
                          />
                        </InputGroup>
                      </div>
                      <div className='col-md-12'>{error && <span className='invalid-feedback'>{error}</span>}</div>
                    </div>
                  </Form>
                </TabPane>
                {edit_items.map((item: StepForm.FieldInfo, index: number) => {
                  return (
                    <TabPane tabId={2 + index} key={'tab' + index}>
                      <Form
                        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                          e.preventDefault()
                        }}
                      >
                        <div className='row'>
                          <div className='col-sm-12 col-md-12 col-lg-6'>
                            <h5>Current value:</h5>
                            {renderFormField(item, true, true)}
                          </div>
                          <div className='col-sm-12 col-md-12 col-lg-6'>
                            <h5>New value:</h5>
                            {renderFormField(item, true, false)}
                          </div>
                        </div>
                      </Form>
                    </TabPane>
                  )
                })}
              </TabContent>
            </div>
            <div className='actions clearfix'>
              <ul>
                <li className={activeTab === 1 ? 'previous disabled' : 'previous'}>
                  <Link
                    to='#'
                    onClick={() => {
                      toggleTab(activeTab - 1)
                      setError('')
                    }}
                  >
                    Previous
                  </Link>
                </li>
                <li className={activeTab === 4 ? 'next disabled' : 'next'}>
                  <Link
                    to='#'
                    onClick={() => {
                      if (reason === '' && activeTab === 1) {
                        setError('This field is required and may not be left empty.')
                        toggleTab(1)
                      } else if (activeTab === edit_items.length + 1) {
                        handleEditSave(reason.trim())
                        setReason('')
                        setError('')
                      } else toggleTab(activeTab + 1)
                    }}
                  >
                    {activeTab === edit_items.length + 1 ? 'Save' : 'Next'}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default FormEditModal
