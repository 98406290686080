import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import './index.scss'

import {
  ColDef,
  FirstDataRenderedEvent,
  GridSizeChangedEvent,
  IRowNode,
  RowClassParams,
  RowStyle
} from 'ag-grid-community'
import { LicenseManager } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import React, { useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'reactstrap'

import { StepTable, TimeAgo } from '@digitalworkflow/dwtranslateclient'

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-067139}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Brians_Com_LLC}_is_granted_a_{Multiple_Applications}_Developer_License_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{25_November_2025}____[v3]_[01]_MTc2NDAyODgwMDAwMA==138cede0283d1caca2b83e456c6fda4a'
)

/**
 * Props for [[`PageGrid`]] component
 */
export interface PageGridProps {
  /** Step data. */
  step: StepTable
}

/**
 * PageGrid component
 *
 * @component PageGrid
 * @category PageElements
 */
const PageGrid = ({ step }: PageGridProps) => {
  const agGridRef = useRef<any>(null)
  const navigate = useNavigate()

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: false,
      unSortIcon: true,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      editable: true,
      resizable: true,
      minWidth: 140
    }
  }, [])

  /**
   * Grid cell renderer.
   *
   * @remarks
   * Used for rendering the grid cell values
   */
  const cellRenderFunc = (props: any, col: StepTable.Column) => {
    if (col.is_action) {
      if (col.type.checkOption('GoToRoute')) {
        return (
          <Button color='primary' size='sm' className='grid-link-btn' onClick={() => navigate(props.data.link)}>
            {props.value}
          </Button>
        )
      }
    }
    if (col.format === 'TimeAgo') {
      return TimeAgo.inWords(new Date(props.value))
    }
    if (props.value === 'undefined' || props.value === undefined || props.value === null) {
      // Prevent undefined render
      return ''
    }
    return props.value
  }

  const colDefs = step.columns.map((col: StepTable.Column, index: number) => {
    // console.log('colDef', col)

    let minWidth = 145
    if (col.text === 'Actions') minWidth = 90

    return {
      field: 'col' + index,
      headerName: col.text,
      sortable: true,
      unSortIcon: true,
      minWidth: minWidth,
      filter: 'agTextColumnFilter',
      // type: col.align == 'right' ? 'rightAligned' : 'centerAligned',
      cellStyle: {
        textAlign: col.align,
        display: 'flex',
        alignItems: 'center',
        justifyContent: col.align === 'right' ? 'flex-end' : col.align === 'center' ? 'center' : 'flex-start'
      },
      headerClass: 'ag-header-align-' + col.align,
      cellRenderer: (props: any) => {
        return cellRenderFunc(props, col)
      },
      floatingFilter: true
    }
  })

  const gridData = step.getFormattedData().map((row: any) => {
    let rowData = {}
    row.map((item: any, idx: number) => {
      rowData = {
        ...rowData,
        ['col' + idx]: item.text
      }

      if (item.link) {
        rowData = {
          ...rowData,
          link: item.link
        }
      }
    })
    // console.log('Row=', row)
    return rowData
  })

  const getRowStyle = (params: RowClassParams) => {
    const formattedData = step.getFormattedData()

    if (params && params.node) {
      const node: IRowNode = params.node
      const rowIndex = node.rowIndex ?? 0

      if (formattedData[rowIndex][0].bgColor === 'Active') {
        return { background: '#dadada' } as RowStyle
      } else if (formattedData[rowIndex][0].bgColor === 'Success') {
        return { background: '#c8e5cb' } as RowStyle
      } else if (formattedData[rowIndex][0].bgColor === 'Default') {
        return { background: '#ffffff' } as RowStyle
      } else if (formattedData[rowIndex][0].bgColor === 'Primary') {
        return { background: '#bfd9fe' } as RowStyle
      } else if (formattedData[rowIndex][0].bgColor === 'Warning') {
        return { background: '#fbefba' } as RowStyle
      } else if (formattedData[rowIndex][0].bgColor === 'Info') {
        return { background: '#c5e5eb' } as RowStyle
      } else if (formattedData[rowIndex][0].bgColor === 'Danger') {
        return { background: '#efc7cb' } as RowStyle
      } else if (formattedData[rowIndex][0].bgColor === 'Secondary') {
        return { background: '#dddee2' } as RowStyle
      } else if (formattedData[rowIndex][0].bgColor === 'Light') {
        return { background: '#fefefe' } as RowStyle
      } else if (formattedData[rowIndex][0].bgColor === 'Dark') {
        return { background: '#c7c9ca' } as RowStyle
      }
    }
    return {}
  }

  const onGridReady = async (params: any) => {
    resizeGridColumns(params)
  }

  /** Resize grid columns to auto fit within the screen width */
  const resizeGridColumns = (params: GridSizeChangedEvent) => {
    const gridApi = params.api
    gridApi.sizeColumnsToFit()
    const allColumnIds: any[] = []
    const columnApi = params.columnApi
    if (columnApi) {
      columnApi.autoSizeColumns(allColumnIds, false)
    }
  }

  /** On screen width change, readjust the grid columns width */
  const onGridViewportResize = (params: GridSizeChangedEvent) => {
    resizeGridColumns(params)
  }

  return (
    <div className='ag-theme-balham page-grid-table'>
      <AgGridReact
        ref={agGridRef}
        columnDefs={colDefs}
        onFirstDataRendered={(params: FirstDataRenderedEvent) => {
          params.api.sizeColumnsToFit()
        }}
        defaultColDef={defaultColDef}
        rowSelection='multiple'
        enableRangeSelection
        rowData={gridData}
        pagination={false}
        onGridReady={onGridReady}
        onGridSizeChanged={onGridViewportResize}
        getRowStyle={getRowStyle}
      />
    </div>
  )
}

export default PageGrid
