import './index.scss'

import React, { useEffect, useRef, useState } from 'react'
import { Row } from 'reactstrap'

import { Notepad } from '@digitalworkflow/dwreactcommon'
import { StepSection } from '@digitalworkflow/dwtranslateclient'

// import { getNotepadColumns, getWidthColumns } from '../../../helpers/pageEngineHelper'

interface Props {
  /** Components to be rendered inside card body.  */
  components: JSX.Element[]
  /** Step data. */
  step: StepSection
}

/**
 * PageSection component
 *
 * @component PageSection
 * @category PageElements
 */
const PageSection = ({ step, components }: Props) => {
  // const sectionWidth = step.raw.step_option?.split('=')?.pop()
  const ref = useRef<any>(null)
  const [padHeight, setPadHeight] = useState<number>(0)

  /* use to caluculate to height of width based on left section
    this is only call on first time when UI loaded of page-section
  */
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          const element = ref.current
          if (element && padHeight <= 0) {
            const height = element.offsetHeight
            setPadHeight(height)
          }
        }
      },
      {
        threshold: 0.1 // Trigger when 10% of the element is visible
      }
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current && observer) {
        observer.unobserve(ref.current)
      }
    }
  }, [])

  if (step.options.checkOption('modules')) {
    return (
      <div className='modulesSection'>
        {components.map((item, index) => (
          <div key={index} className='moduleWrapper'>
            {item}
          </div>
        ))}
      </div>
    )
  }

  if (step.options.checkOption('Notepad')) {
    const valNotepadTitle = step.options.getValue('Notepad', 'Task Notepad')
    return (
      <div className={`${step.raw.step_option} singleSection MainSection`} data-testid='notepad-section'>
        <div className='MainSectionAreaLeft'>
          <Row style={{ height: '100%' }}>
            <div className='card-container h-100 mb-4'>
              <div ref={ref} style={{ height: 'content-fit' }}>
                {components}
              </div>
            </div>
          </Row>
        </div>
        <div className='MainSectionAreaRight'>
          <div className='notepad card-container h-100'>
            <Notepad
              id='1234'
              title={valNotepadTitle}
              projectId={step.logic_ref?.getValue('project_id')}
              objectType='task'
              relatedObject={step.logic_ref?.getValue('task_id')}
              createdBy=''
              updatedBy=''
              padHeight={padHeight}
            />
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className={`section singleSection ${step.raw.step_option} card-container`} data-testid='page-section'>
      {components}
    </div>
  )
}

export default PageSection
