import React, { useEffect, useState } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'

import { StepTabs, StepTypes } from '@digitalworkflow/dwtranslateclient'

import { GlobalHashHelper } from '../../../utils/HashHelper'
import style from './index.module.scss'
import PageTab from './PageTab'

/**
 * Props for [[`PageTabs`]] component
 */
export interface PageTabsProps {
  /** Step data. */
  step: StepTypes.Tabs
  /** Tabs info */
  tabs: StepTabs.TabInfo[]
}

/**
 * PageTabs component
 *
 * @component PageTabs
 * @category PageElements
 */
const PageTabs = ({ tabs }: PageTabsProps) => {
  const [activeTab, setActiveTab] = useState(-1)
  const [badgeCounts, setBadgeCounts] = useState<Array<{ yellow: number; red: number }>>([])
  const [warningMessage, setWarningMessage] = useState<string>('')

  useEffect(() => {
    // Initialize badge counts
    setBadgeCounts(tabs.map(() => ({ yellow: 0, red: 0 })))

    // Set initial active tab
    const hashTab = GlobalHashHelper.getOption('tab')
    if (hashTab) {
      setActiveTab(parseInt(hashTab, 10))
    } else {
      const selectedTabIndex = tabs.findIndex((tab) => tab.type.checkOption('Selected'))
      setActiveTab(selectedTabIndex !== -1 ? selectedTabIndex : 0)
    }
  }, [tabs])

  const handleTabChange = (tabIndex: number) => {
    GlobalHashHelper.setOption('tab', tabIndex)
    setActiveTab(tabIndex)
  }

  const handleBadgeChange = (tabIndex: number, badgeType: string, count: number) => {
    setBadgeCounts((prevCounts) => {
      const newCounts = [...prevCounts]
      if (newCounts[tabIndex] === undefined) {
        newCounts[tabIndex] = { yellow: 0, red: 0 }
      }
      if (badgeType === 'NeedsAnswer') {
        newCounts[tabIndex].red = count
      } else if (badgeType === 'NeedsResolve') {
        newCounts[tabIndex].yellow = count
      }

      if (JSON.stringify(newCounts) !== JSON.stringify(prevCounts)) {
        console.log('handleBadgeChange newCounts', newCounts)
        return newCounts
      }

      return prevCounts
    })

    // Update warning message
    setBadgeCounts((currentCounts) => {
      const hasUnansweredQuestions = currentCounts.some((count) => count && count.red > 0)
      setWarningMessage(hasUnansweredQuestions ? 'There are unanswered questions' : '')
      return currentCounts
    })
  }

  return (
    <>
      <Nav tabs className={`nav-tabs-custom ${style.tabList}`}>
        {tabs.map((tab, index) => (
          <NavItem key={index} className={`${style.tabContainer} tabContainer`}>
            <NavLink
              style={{ cursor: 'pointer' }}
              name={'tab-' + tab.text.replace(/ /g, '-')}
              className={`${activeTab === index ? `active ${style.active}` : ''} ${style.tab} tab`}
              onClick={() => handleTabChange(index)}
            >
              <span className='d-none d-sm-block'>
                {tab.text}
                {badgeCounts[index]?.yellow > 0 && badgeCounts[index]?.red === 0 && (
                  <span className='badge badge-pill badge-warning bg-warning m-1'>{badgeCounts[index].yellow}</span>
                )}
                {badgeCounts[index]?.red > 0 && (
                  <span
                    className='badge badge-pill badge-danger bg-danger m-1'
                    data-toggle='tooltip'
                    data-placement='top'
                    title='There are questions that need to be answered'
                  >
                    {badgeCounts[index].red}
                  </span>
                )}
              </span>
            </NavLink>
          </NavItem>
        ))}
        {warningMessage && (
          <NavItem className={`${style.tabContainer} tabContainer ` + style.tabWarning}>{warningMessage}</NavItem>
        )}
      </Nav>

      <TabContent activeTab={activeTab} className={`text-muted mb-3 ${style.tabBody}`}>
        {tabs.map((tab, index) => (
          <TabPane key={index} tabId={index}>
            <PageTab
              key={'pane' + index}
              tabIndex={index}
              tab={tab}
              onBadgeChange={(badgeType, count) => handleBadgeChange(index, badgeType, count)}
            />
          </TabPane>
        ))}
      </TabContent>
    </>
  )
}

export default PageTabs
