import React from 'react'

import { FormFieldInputProps, useForm } from '../useForm'
import { LookupType } from '@digitalworkflow/dwtranslateclient'

import './index.scss'

const HtmlToReactParser = require('html-to-react').Parser
const htmlToReactParser = new HtmlToReactParser()

/**
 * MultiSelect component
 *
 * @component MultiSelect
 * @category FormElements
 */
const MultiSelect = ({
  step,
  field,
  is_on_modal,
  is_disabled,
  error,
  warning,
  disabledFields,
  editValues,
  handleChangeEditValues,
  handleSetEditValues
}: FormFieldInputProps) => {
  const { myFieldValue, setValue, isEditFieldOnModal, isFieldDisabled, renderValidationError } = useForm({
    step,
    field,
    is_on_modal,
    is_disabled,
    error,
    warning,
    disabledFields,
    editValues,
    handleChangeEditValues,
    handleSetEditValues
  })

  /**
   * Used to change the value of a multiple select field.
   *
   * @remarks
   * If the current field value matches the new value then it sets state
   * and form value without it, otherwise adds it to the current value.
   *
   * @param field - Parser to the field.
   * @param e - New value to add or remove.
   * @returns Void
   */
  const handleChangeRadio = (e: any) => {
    const fieldValue = isEditFieldOnModal() ? editValues[field.field] : myFieldValue

    const valueArray = fieldValue?.split(',').filter((v: any) => {
      return v !== ''
    })

    const newValue = valueArray.includes(e.target.value)
      ? valueArray.filter((v: any) => {
          return v !== e.target.value
        })
      : [...valueArray, e.target.value]

    if (isEditFieldOnModal()) {
      handleChangeEditValues(field, newValue.join(','))
    } else {
      setValue(newValue.join(','))
      // step.setValueFromUser(field.field, newValue.join(','))
    }
  }

  /**
   * Returns the lookup item's text for in selected language
   *
   * @param item - Lookup item
   * @returns {string}
   */
  const getLookupItemText = (item: any) => {
    return item?.text_en
  }

  if (step.tableDef) {
    const list: LookupType[] = step.tableDef.getLookupTable(field.field)

    const fieldValue = isEditFieldOnModal() ? editValues[field.field] : myFieldValue

    const valueArray = fieldValue?.split(',')

    return (
      <div className='flex-grow-1'>
        <div className='multi-select-container'>
          {list
            ? list.map((item: LookupType) => (
                <div className='form-check mb-2' key={item.code}>
                  <input
                    type='checkbox'
                    id={isEditFieldOnModal() ? 'edit_' + field.field + item.code : field.field + item.code}
                    className='form-check-input'
                    name={isEditFieldOnModal() ? 'edit_' + field.field : field.field}
                    value={item.code}
                    checked={valueArray.includes(item.code)}
                    disabled={isFieldDisabled()}
                    onChange={handleChangeRadio}
                  />
                  <label
                    className='form-check-label label'
                    htmlFor={isEditFieldOnModal() ? 'edit_' + field.field + item.code : field.field + item.code}
                  >
                    {htmlToReactParser.parse(getLookupItemText(item))}
                  </label>
                </div>
              ))
            : ''}
        </div>
        {renderValidationError()}
      </div>
    )
  }

  return <></>
}

export default MultiSelect
