import { configureStore } from '@reduxjs/toolkit'
import settingsSlice from '../slices/settingsSlice'
import tasklistSlice from '../slices/tasklistSlice'
import localStorageMiddleware from '../slices/localStorageMiddleware'

export const store = configureStore({
  reducer: {
    tasks: tasklistSlice,
    settings: settingsSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(localStorageMiddleware)
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
