import { FindTaskResults, LogicEngine, LogicEngineDataDateTime, StepTaskList } from '@digitalworkflow/dwtranslateclient'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { TaskListConnectionHelperProps } from './TaskListConnectionProps'

import { updateAllTaskList, updateTaskList, updateTaskListLoaded } from '../../../../redux/slices/tasklistSlice'

export type TaskListConnectionHelperState = {
  status: string
}

export function useTaskListConnectionHelper(props: TaskListConnectionHelperProps): TaskListConnectionHelperState {
  const [status, setStatus] = useState<string>('')

  /** Track the username that the task list and dashboard apply to */
  const [loadedUsername, setLoadedUsername] = useState<string>('')

  const showClosed = useSelector((state: RootState) => state.settings.showClosed)
  const showMuted = useSelector((state: RootState) => state.settings.showMuted)
  const showOther = useSelector((state: RootState) => state.settings.showOther)
  const showRejected = useSelector((state: RootState) => state.settings.showRejected)

  /** Watch the current user, if the user changes, we are going to need a new step */
  const currentUserName = useSelector((state: RootState) => state.settings.currentUsername)
  const currentSearchString = useSelector((state: RootState) => state.settings.currentSearchString)

  const allTaskList = useSelector((state: RootState) => state.tasks[props.sname].allTasks)
  const activeFilters = useSelector((state: RootState) => state.tasks[props.sname].activeFilters)

  const dispatch = useDispatch()

  const currentStep = useRef<StepTaskList | undefined>(undefined)

  const filterTaskList = (rawTaskList: FindTaskResults[]) => {
    // Create a Map to maintain the latest version of each task
    const latestTasksMap = new Map<string, FindTaskResults>()

    // Process all tasks to ensure we have the latest version of each
    rawTaskList.forEach((task) => {
      const existingTask = latestTasksMap.get(task.task_id)
      const taskUpdateDt = new LogicEngineDataDateTime(task.updated_dt)
      const existingTaskUpdateDt = new LogicEngineDataDateTime(existingTask?.updated_dt)
      if (!existingTask || taskUpdateDt > existingTaskUpdateDt) {
        latestTasksMap.set(task.task_id, task)
      }
    })

    // Get all parent tasks
    const allTasks = Array.from(latestTasksMap.values())

    // Get the latest version of all tasks and apply filters
    return allTasks.filter((task) => {
      // Filter out muted tasks if showMuted is false
      if (!showMuted && task.task_status === 'Muted') {
        return false
      }

      // Filter out rejected tasks if showRejected is false
      if (!showRejected && ['Rejected', 'Cancelled'].includes(task.task_status)) {
        return false
      }

      // Filter out closed tasks if showClosed is false
      if (!showClosed && task.task_status === 'Closed') {
        return false
      }

      // Filter by user assignment
      if (!showOther && props.sname === 'dashboard') {
        if (task.auser !== '' && task.auser !== currentUserName) {
          return false
        }
      }

      if (props.sname === 'todo' && !showOther) {
        if (task.auser !== undefined && task.auser !== '' && task.auser !== currentUserName) {
          return false
        }
      }

      // remove parent tasks
      if (task.parent_id && task.parent_id !== '' && task.parent_id.length > 0) {
        return false
      }

      return true
    })
  }

  useEffect(() => {
    if (props.sname === 'search') {
      /** THe user has requested to search for something */
      if (currentSearchString === '') {
        dispatch(updateTaskList({ sname: props.sname, list: [] }))
        dispatch(updateAllTaskList({ sname: props.sname, list: [] }))
        dispatch(updateTaskListLoaded({ sname: props.sname, isLoaded: false }))
      } else {
        initializeStepTaskList()
      }
    }
  }, [currentSearchString])

  const handleNewTasks = (list: FindTaskResults[]) => {
    console.log('TaskListConnectionHelper Handling:', list.length, ' Status=', status)
    if (!list) return

    dispatch(updateAllTaskList({ sname: props.sname, list: list }))

    /** All optional fitlers applied but still all the tasks */
    console.log(props.sname, 'RUNNING FILTER:', showOther, 'FILTERS=', activeFilters)
    const filteredList = filterTaskList(list)
    console.log(props.sname, 'FILTER COMPLETE:', filteredList.length)

    dispatch(updateTaskList({ sname: props.sname, list: filteredList }))
    dispatch(updateTaskListLoaded({ sname: props.sname, isLoaded: true }))
  }

  /**
  Effect is called when the StepTaskList is changed.
  */
  const initializeStepTaskList = () => {
    if (currentStep && currentStep.current) {
      console.log('TaskListConnectionHelper Unsubscribe from previous')
      currentStep.current.unsubscribe()

      /** Clear any previously loaded list of tasks because we have no active step subscribed */
      dispatch(updateTaskList({ sname: props.sname, list: [] }))
      dispatch(updateAllTaskList({ sname: props.sname, list: [] }))
      dispatch(updateTaskListLoaded({ sname: props.sname, isLoaded: false }))
    }

    currentStep.current = new StepTaskList({
      step_type: 'TaskList',
      step_option: props.step_option,
      text_en: props.step_text
    })

    console.log('TaskListConnectionHelper Loading Tasks, user=', currentUserName)
    setStatus('Loading Tasks')

    const logic = new LogicEngine()
    currentStep.current.processStep(logic).then(() => {
      setStatus('Subscribing')
      console.log('TaskListConnectionHelper Subscribing to StepTaskList', currentStep.current, 'user=', currentUserName)
      if (currentStep && currentStep.current) currentStep.current.subscribe(handleNewTasks)
    })
  }

  /** If the current username changes, we should remove the current step and
  wait for another one to be assigned */
  useEffect(() => {
    console.log('TaskListConnectionHelper Username Change to', currentUserName)

    if (currentUserName === '' || currentUserName === undefined) {
      /** Clear any previously loaded list of tasks because we have no active step subscribed */
      dispatch(updateTaskList({ sname: props.sname, list: [] }))
      dispatch(updateAllTaskList({ sname: props.sname, list: [] }))
      dispatch(updateTaskListLoaded({ sname: props.sname, isLoaded: false }))
      return
    }

    if (loadedUsername !== currentUserName) {
      /** Clear any previously loaded list of tasks because we have no active step subscribed */
      setLoadedUsername(currentUserName)
      dispatch(updateTaskList({ sname: props.sname, list: [] }))
      dispatch(updateAllTaskList({ sname: props.sname, list: [] }))
      dispatch(updateTaskListLoaded({ sname: props.sname, isLoaded: false }))
      initializeStepTaskList()
    }
  }, [currentUserName])

  /** Initialize the connection helper because of a re-render which means the user may
  have changed or this is a fresh start without a user
  */
  useEffect(() => {
    // console.log('TaskListConnectionHelper Initialize, Username=', currentUserName)
    setStatus('Initializing')

    if (currentUserName === '') return
    initializeStepTaskList()
  }, [])

  /** Update the list if any of the options change */
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(updateTaskListLoaded({ sname: props.sname, isLoaded: false }))

      /** All optional fitlers applied but still all the tasks */
      const filteredList = filterTaskList(allTaskList)
      dispatch(updateTaskList({ sname: props.sname, list: filteredList }))

      dispatch(updateTaskListLoaded({ sname: props.sname, isLoaded: true }))
    }, 100)

    return () => clearTimeout(timer)
  }, [showMuted, showClosed, showRejected, showOther])

  /*
    const modifyMenuItems = (list: any): any => {
      // Update menu items
      const uniqueProjectIds = new Set(list.map((item: any) => item.project_id))
      return Array.from(uniqueProjectIds).map((projectId) => ({
        item_type: 'Link',
        subtitle_en: projectId,
        tooltip_en: projectId,
        route: location.pathname.includes('/page/dashboard')
          ? `/page/dashboard#type=${projectId}`
          : `/page/tasks#type=${projectId}`,
        view_groups: 'PI, PSI'
      }))
    }
  */

  return {
    status
  } as TaskListConnectionHelperState
}
