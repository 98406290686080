import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { StepMap } from '@digitalworkflow/dwtranslateclient'
import { Mapbox, MapPointType } from '@digitalworkflow/dwreactcommon'
import { MapPriorityCounts, priorityOrder, getMapIconSettings } from './mapBoxHelper'
interface StepMapBoxProps {
  /** Step data. */
  step: StepMap
}
/**
 * StepMapBox component
 *
 * @component StepMapBox
 * @category PageElements
 */
const StepMapBox = ({ step }: StepMapBoxProps) => {
  const [points, setPoints] = useState<MapPointType[]>([])

  /**
   * Fetch the alert counts object.
   * @param { StepMap.MapItem} item .
   * @returns {MapPriorityCounts} return the alert counts.
   */
  const getAlertcount = useCallback((item: StepMap.MapItem) => {
    return {
      emergencyCount: parseInt(item.emergencyCount),
      urgentCount: parseInt(item.urgentCount),
      routineCount: parseInt(item.routineCount),
      sparksCount: parseInt(item.sparksCount),
      nuisanceCount: parseInt(item.nuisanceCount)
    }
  }, [])

  /**
   * @param { StepMap.MapItem} item .
   * @returns {string} return the alert icon class name.
   */
  const getIconName = useCallback((item: StepMap.MapItem) => {
    const counts: MapPriorityCounts = getAlertcount(item)

    /* Get the icon name based on alert priority */

    for (const key of priorityOrder) {
      if (counts[key] && counts[key]! > 0) {
        return getMapIconSettings(key).class
      }
    }
    return ''
  }, [])

  /**
   * @param { StepMap.MapItem} item .
   * @returns {string} return the alert tooltip text.
   */
  const getTooltipText = useCallback((item: StepMap.MapItem) => {
    let text = ''
    const counts: MapPriorityCounts = getAlertcount(item)

    for (const key of priorityOrder) {
      if (counts[key] && counts[key]! > 0) {
        text += `${getMapIconSettings(key).title}: ${counts[key]}\n`
      }
    }
    return text
  }, [])

  /**
   * Parse the {step?.map_items} and set into points state.
   */
  const fetchPoints = useCallback(async () => {
    if (step?.map_items && step.logic_ref) {
      const pointPromises = step.map_items.map(async (item: StepMap.MapItem, index: number) => {
        const [latitude, longitude] = await Promise.all([
          step.logic_ref!.processTextReplacement(item.latitude),
          step.logic_ref!.processTextReplacement(item.longitude)
        ])

        return {
          lat: parseFloat(latitude ?? ''),
          lng: parseFloat(longitude ?? ''),
          iconClass: getIconName(item),
          iconTooltipText: getTooltipText(item),
          showGoogleMaps: item.showGoogleMap.toLowerCase() === 'yes',
          icon: item.icon ?? '',
          label: item.text,
          id: `map-item-${index}`
        }
      })

      const newPoints = await Promise.all(pointPromises)
      setPoints(newPoints)
    } else {
      setPoints([])
    }
  }, [setPoints])

  useEffect(() => {
    fetchPoints()
  }, [step])

  const mapStyles = useMemo(() => {
    // Extract styles from option_1
    const stylesMatch = step.option_1.match(/styles='([^&]+)'/)
    const styles = stylesMatch ? stylesMatch[1].split("','") : []

    // Extract default style from option_1
    const defaultStyleMatch = step.option_1.match(/defaultstyle='([^']+)'/)
    const defaultStyle = defaultStyleMatch ? defaultStyleMatch[1] : undefined

    return {
      mapStyle: defaultStyle,
      availableStyles: styles
    }
  }, [step.option_1])

  return (
    <Mapbox points={points} height={600} mapStyle={mapStyles.mapStyle} availableStyles={mapStyles.availableStyles} />
  )
}

export default StepMapBox
