import { StepWidget } from '@digitalworkflow/dwtranslateclient/lib/PageEngine/StepWidget'
import { SBWidgetTrendLog, SBFloorPlan, WeatherWidget } from '@digitalworkflow/sbpcommon'
import React from 'react'
import './widget.scss'

export interface PageWidgetProps {
  /** Step data. */
  step: StepWidget
}

/**
 * PageWidget component
 *
 * @component PageText
 * @category PageElements
 */
const PageWidget = ({ step }: PageWidgetProps) => {
  const renderFloorPlan = () => {
    return <SBFloorPlan buildingId={step.text} editMode={false} assetName='' floorId='' />
  }

  if (step.hasOptions('Floor')) return renderFloorPlan()

  if (step.hasOptions('Trendlog')) {
    return <SBWidgetTrendLog buildingId={step.text} sensorName={step.option_1} />
  }

  if (step.hasOptions('Weather')) {
    return (
      <div className='widget-component'>
        <WeatherWidget latitude={parseFloat(step.option_1)} longitude={parseFloat(step.option_2)} />
      </div>
    )
  }

  return (
    <div>
      <h3>Page widget</h3>
    </div>
  )
}
export default PageWidget
