import React, { useEffect } from 'react'
import { ActionBarHelperRecord } from './ActionBarHelper'
import { useNavigate } from 'react-router-dom'

import './ActionBar.scss'
import { ActionBarButton } from './ActionBarButton'
import { LogicEngine, PageEngine, PageManager, TaskCollection } from '@digitalworkflow/dwtranslateclient'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GlobalHashHelper } from '../../utils/HashHelper'
import { TaskStatusCode } from '@digitalworkflow/dwtranslateclient/lib/Models/Task/TaskSchema'
import { WorkflowUser } from '@digitalworkflow/dwloginclient'
import ConfirmCancelTask from '../ConfirmModal/ConfirmCancelTask'
import { ActionBarMuteButton } from './ActionBarMuteButton'

type ActionBarProps = {
  buttons: ActionBarHelperRecord[]
  logic: LogicEngine | undefined
  page: PageEngine | undefined
}

export const ActionBar = (props: ActionBarProps) => {
  const [message, setMessage] = React.useState<string>('')

  /** Set to true if no action can be done on this page */
  const [hideAllActions, setHideAllActions] = React.useState<boolean>(false)

  /** Set to true if the current task must be acknowledged before anything else */
  const [mustAck, setMustAck] = React.useState<boolean>(false)

  /** Task is on hold, task must be removed from hold */
  const [mustUnHold, setMustUnHold] = React.useState<boolean>(false)

  /** Is the confirmation dialog showing? */
  const [confirmModalShowing, setConfirmModalShowing] = React.useState<boolean>(false)

  const navigate = useNavigate()

  useEffect(() => {
    const processPage = async () => {
      if (!props.logic) return

      const can_see = await props.logic.getVariable('permission.can_see').isTrue()
      const can_manage = await props.logic.getVariable('permission.can_manage').isTrue()
      const can_action = await props.logic.getVariable('permission.can_action').isTrue()

      const status = (await props.logic.getVariable('task_status').value) || 9999
      const auser = await props.logic.getVariable('auser').value

      let message = `Can See: ${can_see}, Can Manage: ${can_manage}, Can Action: ${can_action}, Task Status: ${status}
        (${TaskStatusCode.toString(status)}), AU: ${auser || 'None'}`
      // setMessage(message)

      if (status === TaskStatusCode.TaskOnHold || status.toString() === TaskStatusCode.TaskOnHold.toString()) {
        if (auser === WorkflowUser.GetCurrentUser().username) {
          setMustUnHold(true)
        } else if (can_manage || can_action) {
          setMustUnHold(true)
        } else {
          setMustUnHold(false)
        }
      } else {
        setMustUnHold(false)
      }

      if (GlobalHashHelper.hasOption('debug')) setMessage(message)

      if (!can_manage && !can_action) {
        setHideAllActions(true)
        return
      } else {
        setHideAllActions(false)
      }

      if ((status === '1' || status === 1) && (!auser || auser === '')) {
        message += '; Must Acknowledge'
        // setMessage(message)
        setMustAck(true)
      } else {
        message += `; ${status === '1' || status === 1} && ${!auser || auser === ''}`
        // setMessage(message)
        setMustAck(false)
      }
    }

    if (GlobalHashHelper.hasOption('debug')) setMessage(message)

    if (props.logic) {
      processPage()
      props.logic.evRunConditions.subscribe(() => {
        processPage()
      })
    } else {
      console.error('Error in ActionBar, expecting logic engine to be setup')
    }
  }, [props.logic, props.buttons])

  const handleConfirmCancel = (didConfirm: boolean) => {
    const handleCancel = async () => {
      if (!props.page) return

      const task_id = await props.logic?.getVariable('task_id').value
      const task = await TaskCollection.findTaskById(task_id)
      if (task) {
        await task.setStatus(TaskStatusCode.TaskCancelled, 'Task Cancelled')
        await task.save()
        const link = PageManager.linkBuilder('/page/dashboard', { task_id: task_id }) + document.location.hash
        setTimeout(() => {
          navigate(link)
        }, 250)
      }
    }

    setConfirmModalShowing(false)
    if (didConfirm) handleCancel()
  }

  /** Someone clicked a button to cancel the current request */
  const handleCancelRequest = () => {
    setConfirmModalShowing(true)
  }

  /** The task is on hold */
  const renderMustUnhold = () => {
    const baseStyle = 'btn w-xs waves-effect me-3 btn-success'

    const handleUnhold = async () => {
      if (!props.page) return

      const task_id = await props.logic?.getVariable('task_id').value
      const task = await TaskCollection.findTaskById(task_id)
      if (task) {
        await task.setStatus(TaskStatusCode.TaskInProgress, 'Task Resumed')
        await task.save()
        setMustUnHold(false)
        const currentRoute = props.page.routePath
        const link = PageManager.linkBuilder(currentRoute, { task_id: task_id }) + document.location.hash
        setTimeout(() => {
          navigate(link)
        }, 250)

        return
      }

      await props.page.runMacro('UnholdTask')
      props.page.evForceRefresh.emit({ refresh_type: 'page', refresh_id: '' })
    }

    return (
      <div className='mustUnhold'>
        <div className='actionMessage'>
          <div className='messageWrapper'>{message}</div>
        </div>
        <div className='actionMessage'>This task is on hold and the information can not be edited at this time.</div>
        <button type='button' className={baseStyle} onClick={() => handleUnhold()}>
          <FontAwesomeIcon icon={['fas', 'power-off']} />
          Resume Task
        </button>
      </div>
    )
  }

  /** This task must be acknowledged to set the task assigned user to continue */
  const renderMustAck = () => {
    const baseStyle = 'btn w-xs waves-effect me-3 btn-success'

    const handleAcknowledge = async () => {
      if (!props.page) return
      console.log('Running Macro')
      await props.page.runMacro('AckCurrentTask')
      setTimeout(() => {
        if (!props.page) return
        props.page.evForceRefresh.emit({ refresh_type: 'page', refresh_id: '' })
      }, 250)
    }

    return (
      <div className='mustAck'>
        <div className='actionMessage'>
          <div className='messageWrapper'>{message}</div>
        </div>
        <div className='actionMessage'>
          This task has not been acknowledged yet. Doing so will assign the task to you and make additional options
          available.
        </div>
        <button type='button' className={baseStyle} onClick={() => handleAcknowledge()}>
          <FontAwesomeIcon icon={['fas', 'check']} />
          Acknowledge
        </button>
      </div>
    )
  }

  if (mustUnHold) {
    return (
      <div className='actionBarTop' key='actionBarTop1'>
        {mustUnHold && renderMustUnhold()}
      </div>
    )
  }

  if (hideAllActions)
    // return <div key='actionBarHidden'>You are currently seeing this information in readonly viewer mode.</div>
    return (
      <div key='actionBarHidden'>
        <div className='messageWrapper'>{message}</div>
      </div>
    )

  return (
    <div className='actionBarTop' key='actionBarTop1'>
      <ConfirmCancelTask isOpen={confirmModalShowing} handleConfirm={handleConfirmCancel} />
      {mustAck && renderMustAck()}
      {!mustAck && (
        <>
          <div>
            <div className='messageWrapper'>{message}</div>
          </div>
          {props.buttons &&
            props.buttons.map((thisButton, idx) =>
              thisButton.opt.option_1.endsWith('MUTE') ? (
                <ActionBarMuteButton
                  key={'ab' + idx}
                  page={props.page}
                  button={thisButton}
                  handleCancelRequest={handleCancelRequest}
                />
              ) : (
                <ActionBarButton
                  key={'ab' + idx}
                  page={props.page}
                  button={thisButton}
                  handleCancelRequest={handleCancelRequest}
                />
              )
            )}
        </>
      )}
    </div>
  )
}
