import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface TaskCountBadgeProps {
  count: number
  color: string
  tooltip: string
}

export const pluralize = {
  is: (count: number) => (count === 1 ? `is ${count}` : `are ${count}`),
  subtask: (count: number) => (count === 1 ? 'subtask' : 'subtasks')
}

const TaskCountBadge: React.FC<TaskCountBadgeProps> = ({ count, color, tooltip }) => {
  if (count === 0) return null

  return (
    <div
      className='d-flex align-items-center position-relative me-3'
      data-tooltip-id='task-list-tooltip'
      data-tooltip-content={`There ${pluralize.is(count)} ${tooltip}`}
    >
      <FontAwesomeIcon icon={['fas', 'circle']} color={color} style={{ fontSize: '22px' }} />
      <div
        className='d-flex align-items-center justify-content-center'
        style={{ position: 'absolute', width: '22px', height: '22px', left: 0 }}
      >
        <span style={{ color: 'white', fontSize: '11px' }}>{count > 99 ? '99+' : count}</span>
      </div>
    </div>
  )
}

export default TaskCountBadge
