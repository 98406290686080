type MapIconSettingsType = { [key: string]: { class: string; title: string } }

export type MapPriorityCounts = {
  emergencyCount?: number
  urgentCount?: number
  routineCount?: number
  sparksCount?: number
  nuisanceCount?: number
}

const MapIconSetting: MapIconSettingsType = {
  emergencyCount: {
    class: 'Danger',
    title: 'Emergency Alert(s)'
  },
  urgentCount: {
    class: 'Primary',
    title: 'Urgent Alert(s)'
  },
  routineCount: {
    class: 'Success',
    title: 'Routine Alert(s)'
  },
  sparksCount: {
    class: 'Info',
    title: 'Spark Alert(s)'
  },
  nuisanceCount: {
    class: 'Info',
    title: 'Normal Alert(s)'
  }
}

export const getMapIconSettings = (name: string | undefined) => {
  if (!name || !MapIconSetting[name])
    return {
      class: 'Info',
      title: ''
    }
  return MapIconSetting[name]
}

export const priorityOrder: (keyof MapPriorityCounts)[] = [
  'emergencyCount',
  'urgentCount',
  'routineCount',
  'sparksCount',
  'nuisanceCount'
]
