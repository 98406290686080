import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'

export interface GroupFlagCellRendererParams extends ICellRendererParams {
  flagCodes: Record<string, string>
}

export class GroupRowInnerRenderer implements ICellRendererComp {
  eGui!: HTMLElement
  params!: GroupFlagCellRendererParams
  dataChangedListener!: () => void

  init(params: GroupFlagCellRendererParams) {
    this.eGui = document.createElement('div')
    this.eGui.style.display = 'inline-block'
    this.params = params
    this.refreshGui()

    this.dataChangedListener = () => {
      this.refreshGui()
    }

    /** TODO: Are these events ever correctly removed? */
    params.api.addEventListener('cellValueChanged', this.dataChangedListener)
    params.api.addEventListener('filterChanged', this.dataChangedListener)
  }

  refreshGui() {
    let html = ''

    const node = this.params.node
    html += `<span class="groupTitle">${node.key}</span>`
    this.eGui.innerHTML = html
  }

  destroy() {}

  getGui() {
    return this.eGui
  }

  refresh(/* params: ICellRendererParams */): boolean {
    return false
  }
}
