import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { Row, Col } from 'reactstrap'
import { FindTaskResults, LogicEngine } from '@digitalworkflow/dwtranslateclient'
import moment from 'moment'

const HtmlToReactParser = require('html-to-react').Parser
const htmlToReactParser = new HtmlToReactParser()

export interface TaskListButtonProps {
  item: FindTaskResults
  setContextTask: (item: FindTaskResults) => void
  setContextMenuPosition: (a: { x: number; y: number }) => void
  renderTaskBadges: (task: FindTaskResults) => ReactElement
  setSubShowingTask?: (task: FindTaskResults) => void
  logicRef?: LogicEngine | undefined
}

const TaskListButton = ({
  item,
  setContextMenuPosition,
  setContextTask,
  renderTaskBadges,
  setSubShowingTask
}: TaskListButtonProps) => {
  const navigate = useNavigate()
  /**
   * Button click handler.
   *
   * @remarks
   * Used for redirecting to targetAction.
   */
  const handleClick = async () => {
    navigate(item.route)
  }

  // static Open: number = 1;
  // static Answered: number = 2;
  // static Resolved: number = 3;
  // static Deleted: number = 4;
  // static NotResolved: number = 5;

  /**
   * Sub tasks badge click handler.
   *
   * @remarks
   * Used for opening subtasks slider
   */
  const openSubtaskSlider = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (setSubShowingTask) setSubShowingTask(item)
  }

  const formatMutedUntil = (mutedUntilDt: string | Date | undefined): string => {
    if (!mutedUntilDt) return ''

    const now = moment()
    const mutedUntil = moment(mutedUntilDt)
    const duration = moment.duration(mutedUntil.diff(now))

    if (duration.asSeconds() <= 0) return ''

    const days = Math.floor(duration.asDays())
    const hours = duration.hours()
    const minutes = duration.minutes()

    if (days > 0) return `${days} ${days > 1 ? 'days' : 'day'}`
    if (hours > 0) return `${hours} ${hours > 1 ? 'hrs' : 'hr'}`
    if (minutes > 0) return `${minutes} ${minutes > 1 ? 'mins' : 'min'}`

    return '0 min'
  }

  const getBuildingIDValue = (cardObject: any) => {
    if (!cardObject?.value || !cardObject?.label) {
      return '' // Return empty if value or label is not available
    }
    let buildingIdValue = cardObject.value
    if (cardObject.label === 'Building ID') {
      const buildings = cardObject.value.split(',')
      if (buildings.length === 1) {
        buildingIdValue = buildings[0] // No change, just the first building ID
      } else if (buildings.length === 2) {
        buildingIdValue = `${buildings[0]} and ${buildings.length - 1} more building` // Two buildings, no pluralization needed
      } else {
        buildingIdValue = `${buildings[0]} and ${buildings.length - 1} more buildings` // Pluralize for more than 2
      }
    }
    return buildingIdValue
  }

  const getValue = (card: any, building_id_value: string) => {
    if (card.label === 'Last Updated' && card.value && typeof card.value === 'object') {
      return moment(card.value.value).format('MMMM Do YYYY, h:mm:ss')
    } else if (card.label === 'Building ID') {
      return building_id_value
    }
    if (card.boolValue && card.value && typeof card.value === 'string') {
      return htmlToReactParser.parse(card.value)
    }
    return null
  }

  //  parseHTMLString() will parse the HTML string and return the text content
  const parseHTMLString = (htmlString: string): string => {
    // Remove all HTML tags
    let text = htmlString.replace(/<\/?[^>]+(>|$)/g, ' ')

    // Decode HTML entities
    text = text
      .replace(/&nbsp;/g, ' ') // Replace non-breaking spaces
      .replace(/&amp;/g, '&') // Replace ampersand
      .replace(/&lt;/g, '<') // Replace less than
      .replace(/&gt;/g, '>') // Replace greater than
      .replace(/&quot;/g, '"') // Replace double quotes
      .replace(/&#39;/g, "'") // Replace single quotes
      .replace(/&apos;/g, "'") // Replace apostrophe

    // Trim extra spaces and return the clean text
    return text.replace(/\s+/g, ' ').trim()
  }

  return (
    <div
      className='module-button'
      onClick={handleClick}
      onContextMenu={(e) => {
        e.preventDefault() // prevent the default behaviour when right clicked
        setContextTask(item)

        const wrapperElement = document.querySelector('.task_list_wrapper-container')

        const wrapperRect = wrapperElement?.getBoundingClientRect()

        const x = e.clientX - (wrapperRect?.left ?? 0)
        const y = e.clientY - (wrapperRect?.top ?? 0)

        setContextMenuPosition({ x, y })
      }}
    >
      <div
        className='module-title'
        data-tooltip-id='task-list-tooltip'
        data-tooltip-content={htmlToReactParser.parse(item.task_title)}
      >
        <span className='module-title-text'>{item.task_title}</span>
      </div>
      <div className='task_detail'>
        <div>
          {item.card_labels &&
            Array.isArray(item.card_labels) &&
            item.card_labels.length > 0 &&
            item.card_labels.map((card: any, index: number) => {
              const building_id_value = getBuildingIDValue(card)
              return (
                card.boolValue &&
                card.value &&
                card.value !== 'undefined' &&
                typeof card.label === 'string' && (
                  <Row key={index} className='p-0'>
                    <Col
                      className='col-3 additional-field-text'
                      data-tooltip-id='task-list-tooltip'
                      data-tooltip-content={card.label}
                    >
                      {card.label}:
                    </Col>
                    <Col
                      className='col-9 additional-field-text'
                      data-tooltip-id='task-list-tooltip'
                      data-tooltip-content={
                        card.label === 'Last Updated' && card.value && typeof card.value === 'object'
                          ? moment(card.value.value).format('MMMM Do YYYY, h:mm:ss')
                          : parseHTMLString(card.value)
                      }
                    >
                      {getValue(card, building_id_value)}
                    </Col>
                  </Row>
                )
              )
            })}
        </div>
        <div
          className='desc desc-text'
          data-tooltip-id='task-list-tooltip'
          data-tooltip-content={item.workflow_description}
        >
          {item.workflow_description}
        </div>
        <div className='row'>
          <div className='cell'>
            Created: {new Date(item?.create_dt as unknown as string).toDateString()}, Updated:
            {` ${new Date(item?.updated_dt as unknown as string).toDateString()}`}
          </div>
          {item.open_query_count > 0 && (
            <div className='cell noteWrapper'>
              <span className='note'>Unanswered Questions: {item.open_query_count}</span>
            </div>
          )}
          {item.task_status === 'Muted' && (
            <div className='cell noteWrapper'>
              <span className='muteLable'>Muted {formatMutedUntil(new Date(item?.muted_until_dt || Date.now()))} </span>
            </div>
          )}
        </div>
        {item.subtask_ids.length > 0 && (
          <div className='badges' onClick={openSubtaskSlider}>
            {renderTaskBadges(item)}
          </div>
        )}
      </div>
    </div>
  )
}

export default TaskListButton
